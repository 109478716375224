import React from 'react'

// import Component from './components/Component/Component'

import styles from './Footnote.module.css'

class Footnote extends React.Component {
    render() {
        const { footnote } = this.props.data;

        return (
            <div className={styles['content']} ref={this.props.innerRef}>
                <span className={styles['asterisk']}>*</span>
                <p className={styles['footnote']} dangerouslySetInnerHTML={{ __html: footnote }}></p>
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <Footnote 
  innerRef={ref} {...props}
/>);