import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import gsap from 'gsap'

import bootstrapService from '../../../services/BootstrapService'

import NavbarToggle from './components/NavbarToggle/NavbarToggle'

import styles from './Navbar.module.css'

class Navbar extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.navbar = null;
        this.loginButton = null;
        this.registerButton = null;

        // Vars
        this.scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;

        // State
        this.state = {
            previousY: 0,
            isMobileNavbarOpen: false,
        }

        // This Binding
        this.scrollNavbar = this.scrollNavbar.bind(this);
        this.clickLogo = this.clickLogo.bind(this);
        this.toggleMobileNavbar = this.toggleMobileNavbar.bind(this);
        this.clickMobileLink = this.clickMobileLink.bind(this);
        this.animateButtonEnter = this.animateButtonEnter.bind(this);
        this.animateButtonLeave = this.animateButtonLeave.bind(this);
    }

    // Lifecycle 
    componentDidMount() {
        this.setupScrollAnimation();
    }

    componentWillUnmount() {
        document.body.style.overflow = 'auto';
    }

    // Event Handlers
    setupScrollAnimation() {
        window.addEventListener("scroll", this.scrollNavbar);
    }

    clickLogo() {
        this.closeMobileNavbar();
        this.props.history.push('/');
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }

    toggleMobileNavbar() {
        if (this.state.isMobileNavbarOpen) {
            this.closeMobileNavbar();
        } else {
            this.openMobileNavbar();
        }
    }

    // Helpers 
    scrollNavbar() {
        if (this.navbar) {
            const y = window.scrollY;
            const isPageStart = y < 100;

            if (this.state.previousY > y) {
                if (isPageStart) {
                    if (!this.props.white) {
                        this.navbar.classList.remove(styles['white']);
                    }

                    this.navbar.classList.remove(styles['shadow']);
                }
                else {
                    this.navbar.classList.add(styles['white']);
                    this.navbar.classList.add(styles['shadow']);
                }

                this.navbar.classList.remove(styles['hide']);
            } 
            else {
                this.navbar.classList.add(styles['hide']);
            }

            if (y <= 0) {
                this.navbar.classList.remove(styles['hide']);
            }

            this.setState({ previousY: y });
        }
    }

    openMobileNavbar() {
        this.setState({ isMobileNavbarOpen: true });
        document.body.style.overflow = 'hidden';
    }

    closeMobileNavbar() {
        this.setState({ isMobileNavbarOpen: false });
        document.body.style.overflow = 'auto';
    }

    // Event Handlers
    clickMobileLink(link) {
        if (link === this.props.active) {
            this.closeMobileNavbar();
        }
    }

    animateButtonEnter(button) {
        gsap.to(button, {
            duration: 0.3,
            scale: 1.05,
        })
    }

    animateButtonLeave(button) {
        gsap.to(button, {
            duration: 0.3,
            scale: 1,
        })
    }

    // Component
    render() {
        const { isMobileNavbarOpen } = this.state;

        const navs = bootstrapService.data.landing.headers;
        const logo = bootstrapService.data.site.logoUrl;
        // const pages = bootstrapService.data.landing.pages;

        const defaultPlanType = bootstrapService.defaultPlanType;
        const defaultAuthorityName = bootstrapService.defaultStateName;

        const ceTerm = bootstrapService.getCETerm();

        return (
            <nav className={`${styles['content']} ${this.props.white ? styles['white'] : ''}`} ref={ref => this.navbar = ref}>
                <div className={styles['navbar']}>
                    <img src={logo} alt='' className={styles['logo']} onClick={this.clickLogo} />
                    <ul className={styles['menu']}>
                        {
                            navs.find(n => n === "approvals") &&
                            <li className={`${styles['menu-item']} ${this.props.active === 'approvals' ? styles['active'] : ''}`}><Link to='/approvals'>Our Approvals</Link></li>
                        }
                        {
                            navs.find(n => n === "courses") &&
                            <li className={`${styles['menu-item']} ${this.props.active === 'courses' ? styles['active'] : ''}`}>
                            {
                                bootstrapService.singleCourseId &&
                                <Link to={`/course/${bootstrapService.singleCourseId}`}>The Course</Link>
                            }
                            {
                                !bootstrapService.singleCourseId &&
                                <Link to='/courses'>Our Courses</Link>
                            }
                            </li>
                        }
                        {
                            navs.find(n => n === "pricing") &&
                            <li className={`${styles['menu-item']} ${this.props.active === 'pricing' ? styles['active'] : ''}`}><Link to={`/pricing/${defaultPlanType}`}>Pricing</Link></li>
                        }
                        {
                            navs.find(n => n === "requirements") &&
                            <li className={`${styles['menu-item']} ${this.props.active === 'requirements' ? styles['active'] : ''}`}><Link to={`/requirements/${defaultAuthorityName}`}>{ceTerm} Requirements</Link></li>
                        }    
                        {/* {
                            navs.map(name => {
                                if (!['approvals','courses','pricing','requirements','try-for-free','no-login','no-register'].includes(name)) {
                                    const page = pages.find(p => p.name == name);
                                    return <li className={"a4-navbar-menu-item" + (activeLink == name ? " -active" : "")}><Link to={`/${name}`}>{page.header}</Link></li>;
                                }
                            })
                        } */}
                    </ul>
                    <div className={styles['account']}>
                        {
                            !navs.find(n => n === "no-login") &&
                            <Link to='/login'><span className={styles['login']} ref={ref => this.loginButton = ref} onMouseEnter={(e) => this.animateButtonEnter(this.loginButton)} onMouseLeave={(e) => this.animateButtonLeave(this.loginButton)}>Login</span></Link>
                        }
                        {
                            !navs.find(n => n === "no-register") &&
                            <Link to='/register'><span className={styles['sign-up']} ref={ref => this.registerButton = ref} onMouseEnter={(e) => this.animateButtonEnter(this.registerButton)} onMouseLeave={(e) => this.animateButtonLeave(this.registerButton)}>Sign Up</span></Link>
                        }
                    </div>
                    <NavbarToggle className={styles['toggle']} toggle={this.toggleMobileNavbar} open={isMobileNavbarOpen}/>
                </div>
                <div className={`${styles['mobile-navbar']} ${isMobileNavbarOpen ? styles['mobile-navbar-open'] : ''}`}>
                    <div className={`${styles['mobile-background']} ${isMobileNavbarOpen ? styles['mobile-background-open'] : ''}`}></div>
                    <div className={`${styles['mobile-content']} ${isMobileNavbarOpen ? styles['mobile-content-open'] : ''}`}>
                        <ul className={styles['mobile-menu']}>
                            {
                                navs.find(n => n === "approvals") &&
                                <li className={`${styles['mobile-menu-item']} ${this.props.active === 'approvals' ? styles['active'] : ''}`} onClick={(e) => this.clickMobileLink('approvals')}>
                                    <Link to='/approvals'>Our Approvals</Link>
                                </li>
                            }
                            {
                                navs.find(n => n === "courses") &&
                                <li className={`${styles['mobile-menu-item']} ${this.props.active === 'courses' ? styles['active'] : ''}`} onClick={(e) => this.clickMobileLink('courses')}>
                                    {
                                        bootstrapService.singleCourseId &&
                                        <Link to={`/course/${bootstrapService.singleCourseId}`}>The Course</Link>
                                    }
                                    {
                                        !bootstrapService.singleCourseId &&
                                        <Link to='/courses'>Our Courses</Link>
                                    }
                                </li>
                            }
                            {
                                navs.find(n => n === "pricing") &&
                                <li className={`${styles['mobile-menu-item']} ${this.props.active === 'pricing' ? styles['active'] : ''}`} onClick={(e) => this.clickMobileLink('pricing')}>
                                    <Link to={`/pricing/${defaultPlanType}`}>Pricing</Link>
                                </li>
                            }
                            {
                                navs.find(n => n === "requirements") &&
                                <li className={`${styles['mobile-menu-item']} ${this.props.active === 'requirements' ? styles['active'] : ''}`} onClick={(e) => this.clickMobileLink('requirements')}>
                                    <Link to={`/requirements/${defaultAuthorityName}`}>{ceTerm} Requirements</Link>
                                </li>
                            }    
                            {/* {
                                navs.map(name => {
                                    if (!['approvals','courses','pricing','requirements','try-for-free','no-login','no-register'].includes(name)) {
                                        const page = pages.find(p => p.name == name);
                                        return <li className={"a4-navbar-menu-item" + (activeLink == name ? " -active" : "")}><Link to={`/${name}`}>{page.header}</Link></li>;
                                    }
                                })
                            } */} 
                        </ul>
                        <div className={styles['mobile-account']}>
                            {
                                !navs.find(n => n === "no-login") &&
                                <Link to='/login'><span className={styles['login']}>Login</span></Link>
                            }
                            {
                                !navs.find(n => n === "no-register") &&
                                <Link to='/register'><span className={styles['sign-up']}>Sign Up</span></Link>
                            }
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

export default withRouter(Navbar);