import React from 'react'
import { debounce } from '../../utils/debounce'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from '../../gsap/SplitText'

import bootstrapService from '../../services/BootstrapService'
import paymentService from '../../services/PaymentService'

import Navbar from '../shared/Navbar/Navbar'
import PricingSortDropdown from './components/PricingSortDropdown/PricingSortDropdown'
import PricingSearchBar from './components/PricingSearchBar/PricingSearchBar'
import PricingTab from './components/PricingTab/PricingTab'
import BuyCourseCard from './components/BuyCourseCard/BuyCourseCard'
import BuyMembershipCard from './components/BuyMembershipCard/BuyMembershipCard'
import BuyBundleCard from './components/BuyBundleCard/BuyBundleCard'
import BuyGroupCard from './components/BuyGroupCard/BuyGroupCard'
import NoCoursesCard from './components/NoCoursesCard/NoCoursesCard'
import Subscribe from '../shared/Subscribe/Subscribe'
import Footer from '../shared/Footer/Footer'
import Pages from '../shared/Pages/Pages'

import styles from './Pricing.module.css'

class Pricing extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.title = null;
        this.divider = null;
        this.controls = [];
        this.plans = [];
        this.noResults = null;
        this.pages = null;

        // State
        this.state = {
            currentPlan: this.props.match.params.plan,
            searchFilter: null,
            sortFilter: null,
            isSortDropdownOpen: false,
            pageNumber: 0,
        }

        // GSAP
        gsap.registerPlugin(ScrollTrigger);
        gsap.registerPlugin(SplitText);

        // Event Binding
        this.clickPlanTab = this.clickPlanTab.bind(this);
        this.sortPlans = this.sortPlans.bind(this);
        this.searchCourses = debounce(this.searchCourses.bind(this), 400);
        this.toggleSortDropdown = this.toggleSortDropdown.bind(this);
        this.closeDropdowns = this.closeDropdowns.bind(this);
        this.changePlansPage = this.changePlansPage.bind(this);
    }
    
    // Lifecyle
    componentDidMount() {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 0);

        window.scrollTo(0, 0);
        this.setupSearchBar();
        this.setupAnimations();
    }

    componentDidUpdate() {
        const currentPlan = this.props.match.params.plan;
        if (currentPlan !== this.state.currentPlan) {
            this.setState({ currentPlan: currentPlan}, () => {
                ScrollTrigger.refresh();
                this.renderPlanAnimations();
            });
        }
    }

    // Helpers
    setupSearchBar() {
        let showSearchBar = this.state.currentPlan === 'individual-courses';
        this.controls[1].style.visibility = showSearchBar ? 'visible' : 'hidden';
        this.controls[1].style.opacity = showSearchBar ? 1 : 0;
    }

    setupAnimations() {
        let split = new SplitText(this.title, {type: 'lines'});
        let elements = split.lines;
        let controlsDelay = 0.45;
        if (window.innerWidth >= 768) {
            elements.push(this.divider);
            controlsDelay = 0.7
        }
        
        gsap.from(elements, {
            duration: 0.5,
            stagger: 0.25,
            delay: 0.3,
            y: 25,
            opacity: 0,
        });

        let topControls = this.state.currentPlan === 'individual-courses' ? this.controls.slice(0, 2).reverse() : this.controls[0];
        let tabControls = this.controls[this.controls.length - 1]
        if (window.innerWidth >= 1200) {
            gsap.from([tabControls, topControls], {
                duration: 0.5,
                stagger: 0.25,
                delay: controlsDelay,
                y: 25,
                opacity: 0,
            });
        }
        else {
            gsap.from(topControls, {
                duration: 0.5,
                delay: controlsDelay,
                y: 25,
                opacity: 0,
            });

            gsap.from(tabControls, {
                duration: 0.5,
                delay: controlsDelay + 0.25,
                y: 25,
                opacity: 0,
            });
        }

        let firstPlans = this.plans.slice(0, 2);
        gsap.from(firstPlans, {
            duration: 0.5,
            stagger: 0.25,
            delay: controlsDelay + 0.5,
            y: 25,
            opacity: 0,
        });

        for (const plan of this.plans.slice(2, this.plans.length)) {
            gsap.from(plan, {
                scrollTrigger: {
                    trigger: plan,
                    start: 'top 95%',
                },
                duration: 0.5,
                y: 25,
                opacity: 0,
            });
        }

        if (this.pages) {
            gsap.from(this.pages, {
                scrollTrigger: {
                    trigger: this.pages,
                    start: 'top 90%',
                },
                duration: 0.5,
                y: 25,
                opacity: 0,
            });
        }
    }

    renderPlanAnimations() {
        if (this.plans.length > 0) {
            gsap.from(this.plans, {
                duration: 0.5,
                stagger: 0.25,
                y: 25,
                opacity: 0,
            });
        }

        if (this.noResults) {
            gsap.from(this.noResults, {
                duration: 0.5,
                y: 25,
                opacity: 0,
            });
        }
    }

    // Event Handlers
    clickPlanTab(e) {
        const plan = e.currentTarget.getAttribute('plan');
        if (this.state.currentPlan !== plan) {
            this.setState({ currentPlan: plan, searchFilter: null }, () => {
                this.props.history.push(`/pricing/${plan}`);

                if (this.state.currentPlan === 'individual-courses') {
                    gsap.to(this.controls[1], {
                        duration: 0.3,
                        opacity: 1,
                        visibility: 'visible'
                    })
                }
                else {
                    gsap.to(this.controls[1], {
                        duration: 0.3,
                        opacity: 0,
                        visibility: 'hidden'
                    })
                }
            })
        }
    }

    sortPlans(sort) {
        this.setState({ sortFilter: sort !== '' ? sort : null }, () => {
            this.renderPlanAnimations();
        });
    }

    searchCourses(e) {
        this.setState({ searchFilter: e.target.value !== '' ? e.target.value.toLowerCase() : null }, () => {
            this.renderPlanAnimations();
        });
    }

    toggleSortDropdown() {
        this.setState({ isSortDropdownOpen: !this.state.isSortDropdownOpen })
    }

    closeDropdowns(e) {
        this.setState({ isSortDropdownOpen: false });
    }

    goToCourse(id) {
        this.props.history.push(`/course/${id}`);
    }

    goToBuy(item, type, schedule) {
        paymentService.reset();
        paymentService.item = { ...item, quantity: 1 };
        paymentService.itemType = type;

        if (schedule) {
            paymentService.schedule = schedule;
        }

        this.props.history.push('/buy-register');
    }

    goToBuyGroup(group) {
        paymentService.reset();
        paymentService.item = { ...group.item, quantity: 1 };
        paymentService.group = { isAdminOnly: false, minMembers: group.memberCountMin, maxMembers: group.memberCountMax };

        this.props.history.push('/create-group');
    }

    sortCourses(a, b) {
        //sort by course.order (first)
        let aval = typeof a.order !== 'undefined' ? a.order : null;
        let bval = typeof b.order !== 'undefined' ? b.order : null;
        if (aval === bval) {
            //sort by course.title (second, when order is same)
            if (a.title === b.title) {
                return 0;
            }
            return a.title < b.title ? -1 : 1;
        }
        return aval > bval ? -1 : 1;
    }

    sortPassTypes(a, b) {
        //sort by passType.order (first)
        let aval = typeof a.order !== 'undefined' ? a.order : null;
        let bval = typeof b.order !== 'undefined' ? b.order : null;
        if (aval === bval) {
            //sort by passType.name (second, when order is same)
            if (a.name === b.name) {
                return 0;
            }
            return a.name < b.name ? -1 : 1;
        }
        return aval > bval ? -1 : 1;
    }

    sortBundleTypes(a, b) {
        //sort by bundleType.order (first)
        let aval = typeof a.order !== 'undefined' ? a.order : null;
        let bval = typeof b.order !== 'undefined' ? b.order : null;
        if (aval === bval) {
            //sort by bundleType.name (second, when order is same)
            if (a.name === b.name) {
                return 0;
            }
            return a.name < b.name ? -1 : 1;
        }
        return aval > bval ? -1 : 1;
    }

    sortGroupTypes(a, b) {
        //sort by groupType.order (first)
        let aval = typeof a.order !== 'undefined' ? a.order : null;
        let bval = typeof b.order !== 'undefined' ? b.order : null;
        if (aval === bval) {
            //sort by groupType.name (second, when order is same)
            if (a.name === b.name) {
                return 0;
            }
            return a.name < b.name ? -1 : 1;
        }
        return aval > bval ? -1 : 1;
    }

    changePlansPage(e, page) {
        this.setState({ pageNumber: page - 1 }, () => {
            let offset = window.innerWidth >= 768 ? (window.innerWidth >= 1600 ? 100 : 30) : -30;
            window.scrollTo({top: this.title.getBoundingClientRect().top + window.scrollY + offset, behavior: 'instant'});
            this.renderPlanAnimations();
        });
    }

    // Data
    getPlans() {
        let plans = null;
        let featuredSorter = null;

        if (bootstrapService.data) {
            if (this.state.currentPlan === 'memberships') {
                plans = bootstrapService.data.passTypes;
                featuredSorter = this.sortPassTypes;
            }
            else if (this.state.currentPlan === 'individual-courses') {
                plans = bootstrapService.data.courseCatalog.courses.filter(c => c.hasOwnProperty("item"));
                featuredSorter = this.sortCourses;
            }
            else if (this.state.currentPlan === 'course-bundles') {
                plans = bootstrapService.data.courseBundleTypes;
                featuredSorter = this.sortBundleTypes;
            }
            else if (this.state.currentPlan === 'group-plans') {
                plans = bootstrapService.data.groupTypes;
                featuredSorter = this.sortGroupTypes;
            }
        }

        if (plans && this.state.searchFilter) {
            plans = plans.filter(p => p.title.toLowerCase().search(this.state.searchFilter) > -1)
        }

        if (plans && this.state.sortFilter) {
            if (this.state.sortFilter === '') {
                plans = plans.sort(featuredSorter)
            }
            else if (this.state.sortFilter === 'title') {
                plans = plans.sort(function(a, b) {
                    if (a.item.title === b.item.title) {
                        return 0;
                    }

                    return a.item.title < b.item.title ? -1 : 1;
                })
            }
            else if (this.state.sortFilter === 'price') {
                plans = plans.sort(function(a, b) {
                    if (a.item.price === b.item.price) {
                        return 0;
                    }

                    return a.item.price < b.item.price ? -1 : 1;
                })
            }
            else {
                console.log('INVALID SORT OPTION')
            }
        }

        return plans;
    }

    // Component
    render() {
        const data =  bootstrapService.data.landing.pages.find(p => p.name === "pricing");

        const header = data ? data.header : null;
        const planFilters = data ? data.itemsFilter : null;
        const sortOptions = data ? data.sortOptions : null;
        const showSearchBar = this.state.currentPlan === 'individual-courses';

        const plans = this.getPlans();
        const pagedPlans = plans ? plans.slice(10*this.state.pageNumber, 10*this.state.pageNumber+10) : null;

        this.plans = [];    // Reset Ref Array

        return (
            <>
                <Navbar active='pricing' />
                <div className={styles['background']}>
                    <section className={styles['content']} onClick={this.closeDropdowns}>
                        <h1 className={styles['title']} ref={ref => this.title = ref}>{header}</h1>
                        <div className={styles['divider']} ref={ref => this.divider = ref}></div>
                        <div className={styles['controls']}>
                            <div className={`${styles['upper-controls']} ${showSearchBar ? styles['upper-controls-search'] : ''}`}>
                                <PricingSortDropdown options={sortOptions} onSort={this.sortPlans} onToggle={this.toggleSortDropdown} isOpen={this.state.isSortDropdownOpen} ref={ref => this.controls[0] = ref} />
                                <PricingSearchBar className={`${showSearchBar ? styles['show-search'] : styles['hide-search']}`} clearInput={!showSearchBar} onChange={this.searchCourses} ref={ref => this.controls[1] = ref} />
                            </div>
                            <div className={styles['tabs']} ref={ref => this.controls[2] = ref}>
                                {
                                    planFilters.map((planName, planIndex) => {
                                        const plan = planName.replace(/\s+/g, "-").trim().toLowerCase();
                                        return <PricingTab selected={plan === this.state.currentPlan} onClick={this.clickPlanTab} plan={plan} key={planIndex}>{planName}</PricingTab>
                                    })
                                }
                            </div>
                        </div>
                        <div className={styles['pricing']}>
                        {
                            pagedPlans && 
                            <>
                                {
                                    this.state.currentPlan === 'memberships' &&
                                    pagedPlans.map((membership, membershipIndex) => {
                                      return <BuyMembershipCard data={membership} key={membershipIndex} onBuy={() => this.goToBuy(membership.item, 'membership')} ref={ref => { if (ref !== null) this.plans.push(ref) }} />
                                    })
                                }
                                {
                                    this.state.currentPlan === 'individual-courses' &&
                                    pagedPlans.map((course, courseIndex) => {
                                      return <BuyCourseCard data={course} key={courseIndex} onBuy={(schedule) => this.goToBuy(course.item, 'course', schedule)} onClick={() => this.goToCourse(course.id)} ref={ref => { if (ref !== null) this.plans.push(ref) }} />
                                    })
                                }
                                {
                                    this.state.currentPlan === 'course-bundles' &&
                                    pagedPlans.map((bundle, bundleIndex) => {
                                      return <BuyBundleCard data={bundle} key={bundleIndex} onBuy={() => this.goToBuy(bundle.item, 'bundle')} ref={ref => { if (ref !== null) this.plans.push(ref) }} />
                                    })
                                }
                                {
                                    this.state.currentPlan === 'group-plans' &&
                                    pagedPlans.map((group, groupIndex) => {
                                      return <BuyGroupCard data={group} key={groupIndex} onBuy={() => this.goToBuyGroup(group.item, 'group')} ref={ref => { if (ref !== null) this.plans.push(ref) }} />
                                    })
                                }
                            </>
                        }
                        {
                            plans && plans.length > 10 &&
                            <Pages count={Math.ceil(plans.length/10)} page={this.state.pageNumber + 1} size="large" variant="outlined" onChange={this.changePlansPage} ref={ref => this.pages = ref} />
                        }
                        {
                            plans.length === 0 &&
                            <NoCoursesCard ref={ref => this.noResults = ref} />
                        }   
                        </div>
                    </section>
                    <Subscribe />
                    <Footer />
                </div>
            </>
        )
    }
}

export default Pricing;