import React from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import image from './images/1.svg'

import styles from './AppStoreButton.module.css'

class AppStoreButton extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.button = null;

        // GSAP
        gsap.registerPlugin(ScrollTrigger);

        // This Binding
        this.mouseEnter = this.mouseEnter.bind(this);
        this.mouseLeave = this.mouseLeave.bind(this);
    }

    // Lifecycle 
    componentDidMount() {
        if (!this.props.noAnimate) {
            setTimeout(() => {
                ScrollTrigger.refresh();
            }, 0);
    
            this.setupAnimations();
        }
    }

    setupAnimations() {
        gsap.from(this.text, {
            scrollTrigger: {
                trigger: this.button,
                start: 'top 85%',
            },
            duration: 0.6,
            opacity: 0,
            ease: 'power4.in'
        })

        gsap.from(this.button, {
            scrollTrigger: {
                trigger: this.button,
                start: 'top 85%',
            },
            duration: 0.6,
            delay: 0.1,
            scale: 0.75,
            opacity: 0,
            ease: 'power1.out'
        });
    }

    // Event Handlers
    mouseEnter(e) {        
        gsap.to(this.button, {
            duration: 0.3,
            scale: 1.03,
        })
    }

    mouseLeave(e) {
        gsap.to(this.button, {
            duration: 0.3,
            scale: 1,
        })
    }

    // Component
    render() {
        return (
            <button className={`${styles['content']} ${this.props.className}`} onClick={this.props.onClick} ref={ref => this.button = ref} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
                <img src={image} alt="" className={styles['image']} />
                <span className={styles['title']}>Download App</span>
            </button>
        )
    }
}

export default AppStoreButton;