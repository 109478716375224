import React from 'react'

import bootstrapService from '../../services/BootstrapService'

import Navbar from '../shared/Navbar/Navbar'
import Hero from './components/Hero/Hero'
import Courses from './components/Courses/Courses'
import HowItWorks from './components/HowItWorks/HowItWorks'
import WhyUs from './components/WhyUs/WhyUs'
import Plans from './components/Plans/Plans'
import AppStore from './components/AppStore/AppStore'
import Testimonials from '../shared/Testimonials/Testimonials'
import TryForFree from './components/TryForFree/TryForFree'
import Subscribe from '../shared/Subscribe/Subscribe'
import Footer from '../shared/Footer/Footer'

import styles from './Home.module.css'

class Home extends React.Component {

    // Lifecyle
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    // Component
    render() {
        const homeData = bootstrapService.data.landing;

        const heroData = homeData.sections.find(s => s.name === "intro");
        const coursesData = homeData.sections.find(s => s.name === "catalog");
        const appStoreData = homeData.sections.find(s => s.name === "appStore");
        const howItWorksData = homeData.sections.find(s => s.name === "works");
        const whyUsData = homeData.sections.find(s => s.name === "features");
        const plansData = homeData.sections.find(s => s.name === "plans");
        const tryForFreeData = homeData.sections.find(s => s.name === "tryForFree");

        return (
            <>
                <Navbar />
                <div className={styles['content']}>
                    <div className={styles['background']}>
                        {
                            heroData && <Hero data={heroData} />
                        }
                        {
                            coursesData && <Courses data={coursesData} />
                        }
                        {
                            howItWorksData && <HowItWorks data={howItWorksData} />
                        }
                    </div>
                    {
                        whyUsData && <WhyUs data={whyUsData} />
                    }
                    {
                        plansData && <Plans data={plansData} />
                    }
                    {
                        appStoreData && <AppStore data={appStoreData} />
                    }
                    <Testimonials />
                    <TryForFree data={tryForFreeData}/>
                    <Subscribe backgroundColor='#F2D3D7' />
                    <Footer />
                </div>
            </>
            
        )
    }
}

export default Home;