import React from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from '../../../../gsap/SplitText'

import image from './images/app-store.png'
import AppStoreButton from '../../../shared/Buttons/AppStoreButton/AppStoreButton'

import styles from './AppStore.module.css'

class AppStore extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.section = null;
        this.content = null;
        this.title = null;
        this.description = null;

        // GSAP
        gsap.registerPlugin(ScrollTrigger);
        gsap.registerPlugin(SplitText);
    }

    // Lifecycle 
    componentDidMount() {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 0);

        this.setupAnimations();
    }

    setupAnimations() {
        gsap.from(this.content, {
            scrollTrigger: {
                trigger: this.section,
                start: 'top 80%',
            },
            duration: 0.5,
            scale: 0.90,
            y: 25,
            opacity: 0,
        })

        var split = new SplitText(this.title, {type: 'lines'});
        gsap.from(split.lines, {
            scrollTrigger: {
                trigger: this.section,
                start: 'top 80%',
            },
            duration: 0.5,
            delay: 0.3,
            stagger: 0.2,
            y: 25,
            opacity: 0,
        });

        gsap.from(this.description, {
            scrollTrigger: {
                trigger: this.section,
                start: 'top 80%',
            },
            duration: 0.5,
            delay: 0.7,
            y: 25,
            opacity: 0,
        });
    }

    // Component
    render() {
        const { header, body, appleUrl } = this.props.data;

        return (
            <section ref={ref => this.section = ref}>
                <div className={styles['content']} ref={ref => this.content = ref}>
                    <h1 className={styles['title']} ref={ref => this.title = ref}>{header}</h1>
                    <p className={styles['description']} ref={ref => this.description = ref}>{body}</p>
                    <a href={appleUrl} target="_blank" rel="noopener noreferrer">
                        <AppStoreButton className={styles['button']} />
                    </a>
                    <img src={image} alt='' className={styles['image']} onLoad={() => ScrollTrigger.refresh()} />
                </div>
            </section>
        )
    }
}

export default AppStore;