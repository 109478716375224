import React from 'react'
import { Link } from 'react-router-dom'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from '../../../../gsap/SplitText'

import CourseCard from './components/CourseCard/CourseCard';
import AllCoursesButton from './components/AllCoursesButton/AllCoursesButton';

import styles from './Courses.module.css'

class Courses extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.title = null;
        this.description = null;
        this.buttons = [];
        this.courses = [];

        // Vars
        this.courseAnimations = [];

        // GSAP
        gsap.registerPlugin(ScrollTrigger);
        gsap.registerPlugin(SplitText);

        // This Binding
        this.animateCourseEnter = this.animateCourseEnter.bind(this);
        this.animateCourseLeave = this.animateCourseLeave.bind(this);
        this.animateButtonEnter = this.animateButtonEnter.bind(this);
        this.animateButtonLeave = this.animateButtonLeave.bind(this);
    }

    // Lifecycle 
    componentDidMount() {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 0);

        this.setupAnimations();
    }

    // Helpers
    setupAnimations() {
        var split = new SplitText(this.title, {type: 'lines'});
        gsap.from([split.lines, this.description], {
            scrollTrigger: {
                trigger: this.title,
                start: 'top 95%',
            },
            duration: 0.5,
            stagger: 0.15,
            y: 25,
            opacity: 0,
        });

        for (const button of this.buttons) {
            gsap.from(button, {
                scrollTrigger: {
                    trigger: button,
                    start: 'top 90%',
                },
                duration: 0.5,
                y: 25,
                opacity: 0,
            });
        }

        if (window.innerWidth >= 1200) {
            for (const [index, course] of this.courses.slice(0, 2).entries()) {
                this.courseAnimations[index] = gsap.from(course, {
                    scrollTrigger: {
                        trigger: course,
                        start: 'top 80%',
                    },
                    duration: 0.5,
                    delay: index % 2 !== 0 ? 0.25 : 0,
                    y: 25,
                    opacity: 0,
                });
            }

            for (const [index, course] of this.courses.slice(2, this.courses.length).entries()) {
                this.courseAnimations[index + 2] = gsap.from(course, {
                    scrollTrigger: {
                        trigger: course,
                        start: 'top 95%',
                    },
                    duration: 0.5,
                    delay: index % 2 !== 0 ? 0.25 : 0,
                    y: 25,
                    opacity: 0,
                });
            }
        }
        else {
            for (const [index, course] of this.courses.entries()) {
                this.courseAnimations[index] = gsap.from(course, {
                    scrollTrigger: {
                        trigger: course,
                        start: 'top 90%',
                    },
                    duration: 0.5,
                    delay: window.innerWidth >= 768 && index % 2 !== 0 ? 0.25 : 0,
                    y: 25,
                    opacity: 0,
                });
            }
        }
    }

    // Event Handlers
    animateCourseEnter(index) {
        if (window.innerWidth >= 768 && this.courseAnimations[index].totalProgress() === 1) {
            gsap.to(this.courses[index], {
                duration: 0.3,
                translateY: -10,
            });
        }
    }

    animateCourseLeave(index) {
        if (window.innerWidth >= 768 && this.courseAnimations[index].totalProgress() === 1) {
            gsap.to(this.courses[index], {
                duration: 0.3,
                translateY: 0,
            });
        }
    }

    animateButtonEnter(button) {
        gsap.to(button, {
            duration: 0.3,
            scale: 1.03,
        });
    }

    animateButtonLeave(button) {
        gsap.to(button, {
            duration: 0.3,
            scale: 1,
        });
    }

    // Component
    render() {
        const { header, body, actionLabel, actionLink, courses } = this.props.data;

        return (
            <section className={styles['content']}>
                <div className={styles['info']}>
                    <h1 className={styles['title']} ref={ref => this.title = ref}>{header}</h1>
                    <p className={styles['description']} ref={ref => this.description = ref}>{body}</p>
                    <Link to={actionLink ? actionLink : ''}>
                        <AllCoursesButton className={styles['button-desktop']} text={actionLabel} ref={ref => this.buttons[0] = ref} onMouseEnter={(e) => this.animateButtonEnter(this.buttons[0])} onMouseLeave={(e) => this.animateButtonLeave(this.buttons[0])} />
                    </Link>
                </div>
                <div className={styles['courses']}>
                    {
                        courses.map((course, courseIndex) => {
                            return  <Link to={`/course/${course.courseId}`} key={courseIndex}>
                                        <CourseCard data={course} index={courseIndex} ref={ref => this.courses[courseIndex] = ref} onMouseEnter={(index) => this.animateCourseEnter(index)} onMouseLeave={(index) => this.animateCourseLeave(index)}/>
                                    </Link>
                        })
                    }
                </div>
                <Link to={actionLink ? actionLink : ''}>
                    <AllCoursesButton className={styles['button-mobile']} ref={ref => this.buttons[1] = ref} onMouseEnter={(e) => this.animateButtonEnter(this.buttons[1])} onMouseLeave={(e) => this.animateButtonLeave(this.buttons[1])} />
                </Link>
            </section>
        )
    }
}

export default Courses;