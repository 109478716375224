import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import gsap from 'gsap'

import formService from '../../services/FormService'
import memberService from '../../services/MemberService'
import recaptchaService from '../../services/RecaptchaService'

import PrimaryButton from '../shared/Buttons/PrimaryButton/PrimaryButton'
import Input from '../shared/Input/Input'
import Toast from '../shared/Toast/Toast'

import styles from './Login.module.css'

class Login extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.container = null;
        this.form = null;
        this.toast = null;

        // State
        this.state = {
            isFormLoading: false,
            toastMessage: null,
        }

        // This Binding
        this.submitForm = this.submitForm.bind(this);
        this.exitToast = this.exitToast.bind(this);
    }

    // Lifecycle
    componentDidMount() {
        this.setupAnimations();
    }

    // Helpers
    setupAnimations() {
        gsap.from(this.container, {
            duration: 0.5,
            opacity: 0,
        })
    }

    // Event Handlers
    submitForm(e) {
        e.preventDefault();

        if (!this.state.isFormLoading) {
            this.exitToast();
    
            const isFormValid = formService.validate(this.form);
            if (!isFormValid) return;
    
            this.setState({ isFormLoading: true });
    
            const email = e.target.email.value.trim();
            const password = e.target.password.value;
    
            let memberRequest = {email, password}
    
            recaptchaService.getToken("login")
            .then(token => {
                memberRequest.recaptchaToken = token;
                
                memberService.login(memberRequest)
                .then(json => {
                    if (json && json.succeeded) {
                        memberService.goToMembers();
                    }
                    else {
                        formService.hideAllErrors(this.form);
                        this.setState({ isFormLoading: false, toastMessage: json && json.errors && json.errors.length ? json.errors[0] : 'Sorry, there was an error' }, () => {
                            gsap.to(this.toast, {
                                duration: 0.5,
                                visibility: 'visible',
                                opacity: 1,
                                y: -25,
                            })
                        })
                    }
                });
            });
        }
    }

    exitToast() {
        gsap.to(this.toast, {
            duration: 0.3,
            opacity: 0,
            y: 25,
            onComplete: () => {
                gsap.set(this.toast, {
                    visibility: 'hidden'
                })
            },
        })
    }

    // Component
    render() {
        return (
            <div className={styles['form-container']} ref={ref => this.container = ref}>
                <h1 className={styles['title']}>Login</h1>
                <form className={styles['form']} ref={ref => this.form = ref} onSubmit={this.submitForm}>
                    <Input className={styles['email-input']} type='email' name='email' placeholder='E-mail' required />
                    <Input className={styles['password-input']} type='password' name='password' placeholder='Password' required />
                    <Link to='/forgot-password' replace><span className={styles['forgot-button']} type='button'>Forgot password?</span></Link>
                    <PrimaryButton loading={this.state.isFormLoading} whiteSpinner={true} noAnimate={true} scale={1.02} className={styles['login-button']} type='submit' formNoValidate>Login</PrimaryButton>
                </form>
                <div className={styles['register-container']}>
                    <span className={styles['register-text']}>Don’t have an account?</span>
                    <Link to='/register' replace><span className={styles['register-button']}>Sign up</span></Link>
                </div>
                <Toast className={styles['toast']} error={true} title='Error' description={this.state.toastMessage} onExit={this.exitToast} ref={ref => this.toast = ref} />
            </div>
        )
    }
}

export default withRouter(Login);