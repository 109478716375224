import React from 'react'

import search from './images/search.svg'

import styles from './CourseSearchBar.module.css'

class CourseSearchBar extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.input = null;

        // This Binding
        this.clickSearchBar = this.clickSearchBar.bind(this);
    }

    // Event Handlers
    clickSearchBar(e) {
        this.input.focus();
    }

    // Component
    render() {
        return (
            <div className={styles['content']} onClick={this.clickSearchBar} ref={this.props.innerRef}>
                <input className={styles['input']} ref={ref => this.input = ref} onChange={this.props.onSearch} type='search' placeholder='Search' />
                <img src={search} alt='' className={styles['icon']} />
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <CourseSearchBar 
  innerRef={ref} {...props}
/>);