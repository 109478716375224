import React from 'react'

import styles from './NavbarToggle.module.css'

class NavbarToggle extends React.Component {
    render() {
        return (
            <button onClick={this.props.toggle} className={`${styles['button']} ${this.props.open ? styles['button-open'] : ''} ${this.props.className}`} type='button' role='navigation'>
                <img className={`${styles['image']} ${this.props.open ? styles['image-open'] : styles['image-closed']}`} alt='' />
                {/* <span className={`${styles['burger']} ${this.props.open ? styles['burger-open'] : ''}`}>
                    <span className={`${styles['middle']} ${this.props.open ? styles['middle-open'] : ''}`}></span>
                </span> */}
            </button>
        )
    }
}

export default NavbarToggle;