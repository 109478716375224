import React from 'react'

import styles from './ApprovalInfo.module.css'

class ApprovalInfo extends React.Component {
    render() {
        return (
            <div className={`${styles['content']} ${this.props.className}`}>
                <p className={styles['property']}>{this.props.property}</p>
                <p className={styles['value']}>{this.props.value}</p>
            </div>
        )
    }
}

export default ApprovalInfo;