import React from 'react'
import gsap from 'gsap'

import styles from './ExitButton.module.css'

class ExitButton extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.button = null;

        // This Binding
        this.animateButtonEnter = this.animateButtonEnter.bind(this);
        this.animateButtonLeave = this.animateButtonLeave.bind(this);
    }

    // Event Handlers
    animateButtonEnter() {
        gsap.to(this.button, {
            duration: 0.3,
            scale: 1.04,
        })
    }

    animateButtonLeave() {
        gsap.to(this.button, {
            duration: 0.3,
            scale: 1,
        })
    }

    // Component
    render() {
        return (
            <button className={`${styles['exit']} ${this.props.className}`} ref={ref => this.button = ref} onClick={this.props.onClick} onMouseEnter={this.animateButtonEnter} onMouseLeave={this.animateButtonLeave}>
                <img className={styles['image']} alt='' />
            </button>
        )
    }
}

export default ExitButton;