import React from 'react'  
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import bootstrapService from '../../services/BootstrapService'

import Navbar from '../shared/Navbar/Navbar'
import Subscribe from '../shared/Subscribe/Subscribe'
import Footer from '../shared/Footer/Footer'

import styles from './RefundPolicy.module.css'

class RefundPolicy extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.title = null;
        this.divider = null;
        this.toc = null;
        this.prefaces = [];
        this.topics = [];

        // GSAP
        gsap.registerPlugin(ScrollTrigger);

        // This Binding
        this.clickTopic = this.clickTopic.bind(this);
    }

     // Lifecycle 
     componentDidMount() {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 0);

        window.scrollTo(0, 0);
        this.setupAnimations();
    }

    setupAnimations() {
        let elements = [this.title];
        let prefaceDelay = 0.45;
        if (window.innerWidth >= 1200) {
            elements.push([this.divider, this.toc]);
            prefaceDelay = 0.95
        }
        else if (window.innerWidth >= 768) {
            elements.push([this.divider]);
            prefaceDelay = 0.7
        }
        
        gsap.from(elements, {
            duration: 0.5,
            delay: 0.3,
            stagger: 0.25,
            y: 25,
            opacity: 0,
        });

        gsap.from(this.prefaces, {
            duration: 0.5,
            delay: prefaceDelay,
            y: 25,
            opacity: 0,
        });

        gsap.from(this.topics[0], {
            duration: 0.5,
            delay: prefaceDelay + 0.15,
            y: 25,
            opacity: 0,
        });

        for (const topic of this.topics.slice(1, this.topics.length)) {
            gsap.from(topic, {
                scrollTrigger: {
                    trigger: topic,
                    start: 'top 90%',
                },
                duration: 0.5,
                y: 25,
                opacity: 0,
            });
        }
    }

    // Event Handlers
    clickTopic(e) {
        const topic = e.currentTarget.getAttribute("topic");
        for (const ref of this.topics) {
            if (ref.getAttribute("topic") === topic) {
                ref.scrollIntoView({ behavior: 'smooth', block: 'center' });
                return;
            }
        }
    }

    // Component
    render() {
        const data = bootstrapService.data.landing.pages.find(p => p.name === 'refund-policy');

        const header = data ? data.header : null;
        const effectiveDate = data ? data.effectiveDate : null;
        const body = data ? data.body : null;
        const topics = data ? data.topics : null;

        return (
            <>
                <Navbar white={true} />
                    <section className={styles['content']}>
                        <h1 className={styles['title']} ref={ref => this.title = ref}>{header}</h1>
                        <div className={styles['divider']} ref={ref => this.divider = ref}></div>
                        <div className={styles['outer-container']}>
                            <ol className={styles['toc-container']} ref={ref => this.toc = ref}>
                                {
                                    topics.map((topic, topicIndex) => {
                                        return  <li key={topicIndex}><button className={styles['toc-button']} topic={topic.name.toLowerCase()} onClick={this.clickTopic}>{topic.name}</button> </li>      
                                    })
                                }
                            </ol>
                            <div className={styles['topics-container']}>
                                <h4 className={styles['effective-date']} ref={ref => this.prefaces[0] = ref}>Effective date: {effectiveDate}.</h4>
                                <p className={styles['preface']} dangerouslySetInnerHTML={{ __html: body }} ref={ref => this.prefaces[1] = ref}></p>
                                <div className={styles['topics']}>
                                    {
                                        topics.map((topic, topicIndex) => {
                                            return  <div className={styles['topic']} topic={topic.name.toLowerCase()} ref={ref => this.topics[topicIndex] = ref} key={topicIndex}>
                                                        <h2 className={styles['topic-title']}>{topic.name}</h2>
                                                        <div className={styles['topic-items']}>
                                                            {
                                                                topic.sections.map((item, itemIndex) => {
                                                                    return <div key={itemIndex}>
                                                                        {
                                                                            item.title && // FIX
                                                                            <h3 className={styles['item-title']}>{item.header}</h3>
                                                                        }
                                                                        <p className={styles['item-body']} dangerouslySetInnerHTML={{ __html: item.body }}></p>
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    </div>      
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className={styles['background']}>
                        <Subscribe className={styles['subscribe']} />
                    </div>
                    <Footer />
            </>
        )
    }
}

export default RefundPolicy;