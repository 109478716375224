import React from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from '../../../gsap/SplitText'

import bootstrapService from '../../../services/BootstrapService'

import Testimonial from './components/Testimonial/Testimonial'

import styles from './Testimonials.module.css'

class Testimonials extends React.Component {

    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.draggable = null;
        this.title = null;
        this.testimonials = [];
        this.dividers = [];

        // State
        this.state = {
            isDown: false,
            startX: 0,
            scrollLeft: 0,
        }

        // GSAP
        gsap.registerPlugin(ScrollTrigger);
        gsap.registerPlugin(SplitText);

        // This Binding
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
    }

    // Lifecycle 
    componentDidMount() {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 0);

        this.setupAnimations();
    }
    
    setupAnimations() {
        var split = new SplitText(this.title, {type: 'lines'});
        gsap.from(split.lines, {
            scrollTrigger: {
                trigger: this.title,
                start: 'top 90%',
            },
            duration: 0.5,
            stagger: 0.15,
            y: 25,
            opacity: 0,
        });

        for (const [index, testimonial] of this.testimonials.entries()) {
            gsap.from(testimonial, {
                scrollTrigger: {
                    trigger: testimonial,
                    start: 'top 90%',
                },
                duration: 0.5,
                delay: window.innerWidth < 1024 ? 0.25 * index : 0,
                y: 25,
                opacity: 0,
            });
        }

        for (const [index, divider] of this.dividers.entries()) {
            gsap.from(divider, {
                scrollTrigger: {
                    trigger: divider,
                    start: 'top 90%',
                },
                duration: 0.5,
                delay: window.innerWidth < 1024 ? 0.15 * index : 0,
                y: 25,
                opacity: 0,
            });
        }
    }

    // Event Handlers
    onMouseDown(e) {
        this.setState({ isDown: true, startX: e.pageX - this.draggable.offsetLeft, scrollLeft: this.draggable.scrollLeft});
        this.draggable.classList.add(styles['dragging']);
    }

    onMouseUp(e) {
        this.setState({ isDown: false });
        this.draggable.classList.remove(styles['dragging']);
    }

    onMouseMove(e) {
        if(!this.state.isDown) return;
        e.preventDefault();
        const x = e.pageX - this.draggable.offsetLeft;
        const move = (x - this.state.startX) * 1; // increase to drag faster
        this.draggable.scrollLeft = this.state.scrollLeft - move;
    }

    // Component
    render() {
        const data = bootstrapService.data.landing.sections.find(s => s.name === "testimonials");
        const header = data ? data.header : null;
        const testimonials = data ? data.testimonials : null;

        return (
            <section className={`${styles['content']} ${this.props.className}`}>
                <h1 className={styles['title']} ref={ref => this.title = ref}>{header}</h1>
                <div className={styles['testimonials']} ref={ref => this.draggable = ref} onMouseDown={this.onMouseDown} onMouseUp={this.onMouseUp} onMouseLeave={this.onMouseUp} onMouseMove={this.onMouseMove}>
                    {
                        testimonials.map((testimonial, testimonialIndex) => {
                            return  <React.Fragment key={testimonialIndex}>
                                        <Testimonial data={testimonial} ref={ref => this.testimonials[testimonialIndex] = ref} />
                                        {
                                            testimonialIndex < testimonials.length - 1 &&
                                            <div className={styles['divider']} ref={ref => this.dividers[testimonialIndex] = ref}></div>
                                        }
                                    </React.Fragment>
                        })
                    }
                </div>
            </section>
        )
    }
}

export default Testimonials;