import React from 'react'

import { ReactComponent as Arrow } from './images/arrow.svg';

import styles from './ArrowButton.module.css'

class ArrowButton extends React.Component {
    render() {
        return (
            <button className={`${styles['arrow']} ${this.props.className}`} onClick={this.props.onClick} ref={this.props.innerRef}>
                <Arrow />
            </button>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <ArrowButton 
  innerRef={ref} {...props}
/>);