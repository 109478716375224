import React from 'react'
import { Link } from 'react-router-dom'
import { debounce } from '../../utils/debounce'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from '../../gsap/SplitText'

import bootstrapService from '../../services/BootstrapService'

import Navbar from '../shared/Navbar/Navbar'
import CourseCategoryDropdown from './components/CourseCategoryDropdown/CourseCategoryDropdown'
import CourseSortDropdown from './components/CourseSortDropdown/CourseSortDropdown'
import CourseSearchBar from './components/CourseSearchBar/CourseSearchBar'
import CourseCard from './components/CourseCard/CourseCard'
import NoCoursesCard from './components/NoCoursesCard/NoCoursesCard'
import Subscribe from '../shared/Subscribe/Subscribe'
import Footer from '../shared/Footer/Footer'
import Pages from '../shared/Pages/Pages'

import styles from './Courses.module.css'

class Courses extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.title = null;
        this.divider = null;
        this.controls = [];
        this.courses = [];
        this.noResults = null;
        this.pages = null;

        // Vars
        this.firstCourseAnimations = null;
        this.otherCoursesAnimations = [];

        // State
        this.state = {
            searchFilter: null,
            categoryFilter: null,
            sortFilter: null,
            isCategoryDropdownOpen: false,
            isSortDropdownOpen: false,
            pageNumber: 0,
        }

        // GSAP
        gsap.registerPlugin(ScrollTrigger);
        gsap.registerPlugin(SplitText);

        // This Binding
        this.searchCourses = debounce(this.searchCourses.bind(this), 400);
        this.filterCourses = this.filterCourses.bind(this);
        this.sortCourses = this.sortCourses.bind(this);
        this.toggleCategoryDropdown = this.toggleCategoryDropdown.bind(this);
        this.toggleSortDropdown = this.toggleSortDropdown.bind(this);
        this.closeDropdowns = this.closeDropdowns.bind(this);
        this.changeCoursesPage = this.changeCoursesPage.bind(this);
        this.animateCourseEnter = this.animateCourseEnter.bind(this);
        this.animateCourseLeave = this.animateCourseLeave.bind(this);
    }

    // Lifecycle 
    componentDidMount() {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 0);

        window.scrollTo(0, 0);
        this.setupAnimations();
    }

    // Helpers
    setupAnimations() {
        let split = new SplitText(this.title, {type: 'lines'});
        let elements = split.lines;
        let controlsDelay = 0.45;
        if (window.innerWidth >= 768) {
            elements.push(this.divider);
            controlsDelay = 0.7
        }
        
        gsap.from(elements, {
            duration: 0.5,
            delay: 0.3,
            stagger: 0.25,
            y: 25,
            opacity: 0,
        });

        if (window.innerWidth >= 1200) {
            gsap.from(this.controls, {
                duration: 0.5,
                stagger: 0.20,
                delay: controlsDelay,
                y: 25,
                opacity: 0,
            });
        }
        else {
            gsap.from(this.controls[0], {
                duration: 0.5,
                delay: controlsDelay,
                y: 25,
                opacity: 0,
            });

            gsap.from([this.controls[1], this.controls[2]], {
                duration: 0.5,
                delay: controlsDelay + 0.25,
                y: 25,
                opacity: 0,
            });
        }

        let firstCourses = this.courses.slice(0, 1);
        if (window.innerWidth >= 1200) { firstCourses = this.courses.slice(0, 2) };

        this.firstCourseAnimations = gsap.from(firstCourses, {
            duration: 0.5,
            stagger: 0.25,
            delay: controlsDelay + 0.5,
            y: 25,
            opacity: 0,
        });

        const start = window.innerWidth >= 1200 ? 2 : 1;
        for (const [index, course] of this.courses.slice(start, this.courses.length).entries()) {
            this.otherCoursesAnimations[index] = gsap.from(course, {
                scrollTrigger: {
                    trigger: course,
                    start: 'top 95%',
                },
                duration: 0.5,
                delay: window.innerWidth > 768 && index % 2 !== 0 ? 0.25 : 0,
                y: 25,
                opacity: 0,
            });
        }

        gsap.from(this.pages, {
            scrollTrigger: {
                trigger: this.pages,
                start: 'top 90%',
            },
            duration: 0.5,
            y: 25,
            opacity: 0,
        });
    }

    renderCourseAnimations() {
        if (this.courses.length > 0) {
            gsap.from(this.courses, {
                duration: 0.5,
                stagger: 0.25,
                y: 25,
                opacity: 0,
            });
        }

        if (this.noResults) {
            gsap.from(this.noResults, {
                duration: 0.5,
                y: 25,
                opacity: 0,
            });
        }
    }

    isCourseAnimationCompleted(index) {
        if (index <= 1) { // first 2 courses
            return this.firstCourseAnimations.totalProgress() === 1; 
        }
        else { // other courses
            return this.otherCoursesAnimations[index - 2].totalProgress() === 1;
        }
    }

    // Event Handlers
    searchCourses(e) {
        this.setState({ 
            searchFilter: e.target.value !== "" ? e.target.value.toLowerCase() : null,
            pageNumber: 0, 
        }, () => {
            this.renderCourseAnimations();
        });
    }

    filterCourses(category) {
        this.setState({ 
            categoryFilter: category !== "" ? category : null,
            pageNumber: 0, 
        }, () => {
            this.renderCourseAnimations();
        });
    }

    sortCourses(sort) {
        this.setState({ 
            sortFilter: sort !== "" ? sort : null,
            pageNumber: 0, 
        }, () => {
            this.renderCourseAnimations();
        });
    }

    toggleCategoryDropdown() {
        if (this.state.isSortDropdownOpen) {
            this.setState({ isSortDropdownOpen: false });
        }

        this.setState({ isCategoryDropdownOpen: !this.state.isCategoryDropdownOpen })
    }

    toggleSortDropdown() {
        if (this.state.isCategoryDropdownOpen) {
            this.setState({ isCategoryDropdownOpen: false });
        }

        this.setState({ isSortDropdownOpen: !this.state.isSortDropdownOpen })
    }

    closeDropdowns(e) {
        this.setState({ isCategoryDropdownOpen: false, isSortDropdownOpen: false });
    }

    changeCoursesPage(e, page) {
        this.setState({ pageNumber: page - 1 }, () => {
            let offset = window.innerWidth >= 768 ? (window.innerWidth >= 1600 ? 100 : 30) : -30;
            window.scrollTo({top: this.title.getBoundingClientRect().top + window.scrollY + offset, behavior: 'instant'});
            this.renderCourseAnimations();
        });
    }

    animateCourseEnter(index) {
        if (window.innerWidth >= 1200) {
            if (this.isCourseAnimationCompleted(index)) {
                gsap.to(this.courses[index], {
                    translateY: -10,
                });
            }
        }
    }

    animateCourseLeave(index) {
        if (window.innerWidth >= 1200) {
            if (this.isCourseAnimationCompleted(index)) {
                gsap.to(this.courses[index], {
                    translateY: 0,
                });
            }
        }
    }

    // Data
    getCourses() {
        const allCourses = bootstrapService.data ? bootstrapService.data.courseCatalog.courses : null;
        let courses = allCourses;
        
        if (courses && this.state.categoryFilter) {
            courses = courses.filter(c => c.subjectArea === this.state.categoryFilter)
        }

        if (courses && this.state.searchFilter) {
            courses = courses.filter(c => c.title.toLowerCase().search(this.state.searchFilter) > -1)
        }

        if (courses && this.state.sortFilter) {
            if (this.state.sortFilter === '') {
                courses = courses.sort(function (a, b) {
                    //sort by course.order (first)
                    let aval = typeof a.order !== 'undefined' ? a.order : null;
                    let bval = typeof b.order !== 'undefined' ? b.order : null;
                    if (aval === bval) {
                        //sort by course.title (second, when order is same)
                        if (a.title === b.title) {
                            return 0;
                        }
                        return a.title < b.title ? -1 : 1;
                    }
                    return aval > bval ? -1 : 1;
                })
            }
            else if (this.state.sortFilter === 'title') {
                courses = courses.sort(function (a, b) {
                    if (a.title === b.title) {
                        return 0;
                    }
                    return a.title < b.title ? -1 : 1;
                })
            }
            else if (this.state.sortFilter === 'credits') {
                courses = courses.sort(function (a, b) {
                    //sort by course.credits (first)
                    if (a.credits === b.credits) {
                        //sort by course.title (second, when order is same)
                        if (a.title === b.title) {
                            return 0;
                        }
                        return a.title < b.title ? -1 : 1;
                    }
                    return a.credits < b.credits ? -1 : 1;
                })
            }
            else if (this.state.sortFilter === 'release-date') {
                courses = courses.sort(function (a, b) {
                    if (a.releaseDate === b.releaseDate) {
                        return 0;
                    }
                    return a.releaseDate > b.releaseDate ? -1 : 1;
                })
            }
            else {
                console.log("INVALID SORT OPTION")
            }
        }

        return courses
    }

    // Component
    render() {
        const coursesData = bootstrapService.data.landing.pages.find(p => p.name === "course-catalog");

        const header = coursesData ? coursesData.header : null;
        const categoryFilters = coursesData ? coursesData.subjectAreaFilters : null;
        const sortOptions = coursesData ? coursesData.sortOptions : null;

        const courses = this.getCourses();
        const pagedCourses = courses ? courses.slice(10 * this.state.pageNumber, 10 * this.state.pageNumber + 10) : null;

        this.controls = []; // Reset Ref Array
        this.courses = [];  // Reset Ref Array

        return (
            <>
                <Navbar active='courses' />
                <div className={styles['background']}>
                    <section className={styles['content']} onClick={this.closeDropdowns}>
                        <h1 className={styles['title']} ref={ref => this.title = ref}>{header}</h1>
                        <div className={styles['divider']} ref={ref => this.divider = ref}></div>
                        <div className={styles['controls']}>
                            <CourseCategoryDropdown categories={categoryFilters} onFilter={this.filterCourses} onToggle={this.toggleCategoryDropdown} isOpen={this.state.isCategoryDropdownOpen} ref={ref => this.controls[0] = ref} />
                            <div className={styles['lower-controls']}>
                                <CourseSortDropdown options={sortOptions} onSort={this.sortCourses} onToggle={this.toggleSortDropdown} isOpen={this.state.isSortDropdownOpen} ref={ref => this.controls[1] = ref} />
                                <CourseSearchBar onSearch={this.searchCourses} ref={ref => this.controls[2] = ref} />
                            </div>
                        </div>
                        <div className={styles['courses']}>
                            {
                                pagedCourses &&
                                pagedCourses.map((course, courseIndex) => {
                                    return  <Link to={`/course/${course.id}`} key={courseIndex}><CourseCard course={course} index={courseIndex} ref={ref => { if (ref !== null) this.courses.push(ref) }} onMouseEnter={(index) => this.animateCourseEnter(index)} onMouseLeave={(index) => this.animateCourseLeave(index)} /></Link>  
                                })
                            }
                            {
                                courses && courses.length > 10 &&
                                <Pages count={Math.ceil(courses.length / 10)} page={this.state.pageNumber + 1} size="large" variant="outlined" onChange={this.changeCoursesPage} ref={ref => this.pages = ref} />
                            }
                            {
                                courses.length === 0 &&
                                <NoCoursesCard ref={ref => this.noResults = ref} />
                            }
                        </div>
                    </section>
                    <Subscribe />
                    <Footer />
                </div>
            </>
        )
    }
}

export default Courses;