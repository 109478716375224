import React from 'react'
import Pagination from '@mui/material/Pagination';

import styles from './Pages.module.css'

class Pages extends React.Component {
    // Component
    render() {
        return (
            <div className={styles['pagination']} ref={this.props.innerRef}>
                <Pagination {...this.props} />
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <Pages
    innerRef={ref} {...props}
/>);