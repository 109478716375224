import React from 'react'

// import Component from './components/Component/Component'

import styles from './RequirementCard.module.css'

class RequirementCard extends React.Component {
    render() {
        const { profession, requirement } = this.props.data;
        
        return (
            <div className={styles['content']} ref={this.props.innerRef}>
                <div className={styles['title-container']}>
                    <h3 className={styles['title']}>{profession}</h3>
                </div>
                <p className={styles['description']} dangerouslySetInnerHTML={{ __html: requirement }}></p>
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <RequirementCard 
  innerRef={ref} {...props}
/>);