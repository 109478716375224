import React from 'react'
import gsap from 'gsap'

import imagePreloadService from '../../../services/ImagePreloadService'

import authenticateImage from './images/authenticate.png'
import authenticate2Image from './images/authenticate2.png'
import faqImage from './images/faq.png'
import membershipImage from './images/membership.png'
import courseImage from './images/course.png'
import bundleImage from './images/bundle.png'

import Login from '../../Login/Login'
import Register from '../../Register/Register'
import ForgotPassword from '../../ForgotPassword/ForgotPassword'
import ResetPassword from '../../ResetPassword/ResetPassword'
import ConfirmEmail from '../../ConfirmEmail/ConfirmEmail'
import CompleteProfile from '../../CompleteProfile/CompleteProfile'
import BuyLogin from '../../BuyLogin/BuyLogin'
import BuyRegister from '../../BuyRegister/BuyRegister'
import Payment from '../../Payment/Payment'
import AskQuestion from '../../AskQuestion/AskQuestion'
import ExitButton from '../Buttons/ExitButton/ExitButton'

import styles from './Modal.module.css'
import paymentService from '../../../services/PaymentService'

class Modal extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.modal = null;

        // State
        this.state = {
            isImageLoaded: false,
            currentPath: this.props.location.pathname,
        }

        // This Binding
        this.clickExit = this.clickExit.bind(this);
    }

    // Lifecycle
    componentDidMount() {
        if (window.innerWidth < 1200) {
            this.setupAnimations();
        }
    }

    componentDidUpdate() {
        const path = this.props.location.pathname;
        if (this.state.currentPath !== path) {
            this.setState({ currentPath: path })
        }

        if (this.state.isImageLoaded && window.innerWidth >= 1200) {
            this.setupAnimations();
        }
    }

    // Helpers
    setupAnimations() {
        gsap.to(this.modal, {
            duration: 0.3,
            opacity: 1,
            ease: 'power1.out'
        });
    }

    getModalImage() {
        let image = authenticateImage;
        let style = styles['image-auth'];

        if (this.state.currentPath.startsWith('/login')) {
            image = authenticateImage;
            style = styles['image-auth'];
        }
        else if (this.state.currentPath.startsWith('/register')) {
            image = authenticateImage;
            style = styles['image-auth'];
        }
        else if (this.state.currentPath.startsWith('/forgot-password')) {
            image = authenticateImage;
            style = styles['image-auth'];
        }
        else if (this.state.currentPath.startsWith('/reset-password')) {
            image = authenticateImage;
            style = styles['image-auth'];
        }
        else if (this.state.currentPath.startsWith('/confirm-email')) {
            image = authenticateImage;
            style = styles['image-auth'];
        }
        else if (this.state.currentPath.startsWith('/complete-profile')) {
            image = authenticateImage;
            style = styles['image-auth'];
        }
        else if (this.state.currentPath === '/ask-question') {
            image = faqImage;
            style = styles['image-buy'];
        }
        else if (paymentService.itemType === 'membership') {
            image = membershipImage;
            style = styles['image-buy'];
        }
        else if (paymentService.itemType === 'course') {
            image = courseImage;
            style = styles['image-buy'];
        }
        else if (paymentService.itemType === 'bundle') {
            image = bundleImage;
            style = styles['image-buy'];
        }
        else if (paymentService.itemType === 'group') {
            image = membershipImage;
            style = styles['image-buy'];
        }

        return [imagePreloadService.getImageSrc(image), style];
    }

    // Event Handlers
    clickExit() {
        this.props.history.goBack();
    }

    // Component
    render() {
        const [image, style] = this.getModalImage();
        
        return (
            <section className={`${styles['content']} ${this.props.className}`}>
                <div className={styles['card']} ref={ref => this.modal = ref}>
                    <ExitButton className={styles['exit-button']} onClick={this.clickExit} />
                    <div className={styles['left-container']}>
                        {
                            this.state.currentPath.startsWith('/login') &&
                            <Login />
                        }
                        {
                            this.state.currentPath.startsWith('/register') &&
                            <Register />
                        }
                        {
                            this.state.currentPath.startsWith('/forgot-password') &&
                            <ForgotPassword />
                        }
                        {
                            this.state.currentPath.startsWith('/reset-password')  &&
                            <ResetPassword />
                        }
                        {
                            this.state.currentPath.startsWith('/confirm-email')  &&
                            <ConfirmEmail />
                        }
                        {
                            this.state.currentPath.startsWith('/complete-profile')  &&
                            <CompleteProfile />
                        }
                        {
                            this.state.currentPath === '/buy-login' &&
                            <BuyLogin />
                        }
                        {
                            this.state.currentPath === '/buy-register' &&
                            <BuyRegister />
                        }
                        {
                            this.state.currentPath === '/payment' &&
                            <Payment />
                        }
                        {
                            this.state.currentPath === '/ask-question' &&
                            <AskQuestion />
                        }
                    </div>
                    <div className={styles['right-container']}>
                        <img src={image} alt='' className={`${styles['image']} ${style}`} onLoad={() => this.setState({ isImageLoaded: true })} /> 
                    </div>
                </div>
            </section>
        )
    }
}

export default Modal;