import React from 'react'
import gsap from 'gsap'

import bootstrapService from '../../../../services/BootstrapService';

import arrowDown from './images/arrow-down.svg'

import styles from './CourseSortDropdown.module.css'

class CourseSortDropdown extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // State
        this.state = {
            currentSort: null,
            isOpen: this.props.isOpen,
        }

        // This Binding
        this.clickDropdown = this.clickDropdown.bind(this);
        this.clickSort = this.clickSort.bind(this);
    }

    // Lifecycle
    componentDidUpdate(previousProps, previousState) {
        // When Parent Props Change We Need To Trigger Reload
        if (previousProps.isOpen !== this.props.isOpen) {
            this.setState({ isOpen: this.props.isOpen });
            this.animateIcon(this.props.isOpen);
        }
    }

    // Helpers
    animateIcon(isOpen) {
        if (isOpen) {
            gsap.to(this.icon, {
                duration: 0.3,
                rotate: 180,
                ease: 'back.out',
            })
        }
        else {
            gsap.to(this.icon, {
                duration: 0.3,
                rotate: 0,
                ease: 'back.out',
            })
        }
    }

    // Event Handlers
    clickDropdown(e) {
        e.stopPropagation();
        this.props.onToggle();
    }

    clickSort(e) {
        e.stopPropagation();
        const sort = e.currentTarget.getAttribute('sort');
        this.setState({ currentSort: sort === 'Credits' ? bootstrapService.getCreditsTerm(2.0) : sort }, () => {
            this.props.onToggle();
            this.props.onSort(sort.replace(/\s+/g, "-").trim().toLowerCase());
        })
    }

    // Component
    render() {        
        return (
            <div className={`${styles['content']} ${this.props.className}`}>
                <div className={styles['dropdown']} onClick={this.clickDropdown} ref={this.props.innerRef}>
                    <div className={styles['info']}>
                        <span className={styles['title']}>Sort: </span>
                        <span className={styles['selection']}>{this.state.currentSort ? this.state.currentSort : 'Default'}</span>
                    </div>
                    <img src={arrowDown} alt='' className={styles['icon']} ref={ref => this.icon = ref} />
                </div>
                <div className={`${styles['menu']} ${this.state.isOpen ? `${styles['open']}` : `${styles['closed']}`}`}>
                    <div className={styles['sorts']}>
                        <div className={styles['sort']} onClick={this.clickSort} sort='' key={0}>Default</div>
                    {
                        this.props.options.map((option, optionIndex) => {
                            return  <div className={styles['sort']} onClick={this.clickSort} sort={option} key={optionIndex+1}>{option === 'Credits' ? bootstrapService.getCreditsTerm(2.0) : option}</div>
                        })
                    }
                    </div>
                </div>
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <CourseSortDropdown 
  innerRef={ref} {...props}
/>);