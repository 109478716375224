import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import gsap from 'gsap'

import formService from '../../services/FormService'
import paymentService from '../../services/PaymentService'
import memberService from '../../services/MemberService'
import recaptchaService from '../../services/RecaptchaService'
import bootstrapService from '../../services/BootstrapService'
import scheduleService from '../../services/ScheduleService'

import PrimaryButton from '../shared/Buttons/PrimaryButton/PrimaryButton'
import Input from '../shared/Input/Input'
import Toast from '../shared/Toast/Toast'

import styles from './Payment.module.css'

class Payment extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        //Payment options
        this.paymentOptions = bootstrapService.getPaymentOptions();
        if (this.paymentOptions) {
            this.useCreditCard = this.paymentOptions.useCreditCard;
            this.useCreditCardSandbox = this.paymentOptions.useCreditCardSandbox;
            this.usePayPal = this.paymentOptions.usePayPal;
            this.usePayPalSandbox = this.paymentOptions.usePayPalSandbox;
            this.useOffline = this.paymentOptions.useOffline;
            this.numPaymentMethods = (this.useCreditCard ? 1 : 0) + (this.usePayPal ? 1 : 0) + (this.useOffline ? 1 : 0);
        }

        // Refs
        this.container = null;
        this.form = null;
        this.toast = null;

        // State
        this.state = {
            isCreditCardLoading: false,
            isPayPalLoading: false,
            isSandboxPayment: false,
            toastMessage: null,
        }

        // This Binding
        this.getSandboxTicket = this.getSandboxTicket.bind(this);
        this.submitCreditCard = this.submitCreditCard.bind(this);
        this.submitPayPal = this.submitPayPal.bind(this);
        this.exitToast = this.exitToast.bind(this);
    }

    // Lifecycle
    componentDidMount() {
        this.setupAnimations();
    }

    // Helpers
    setupAnimations() {
        gsap.from(this.container, {
            duration: 0.5,
            opacity: 0,
        });
    }

    // Event Handlers
    getSandboxTicket(e) {
        e.preventDefault();

        const useSandbox =
            (paymentService.paymentType === "cc" && this.useCreditCardSandbox) ||
            (paymentService.paymentType === "pp" && this.usePayPalSandbox);

        if (useSandbox) {
            if (e.ctrlKey || e.metaKey) {
                paymentService.getSandboxTicket()
                    .then(json => {
                        if (json.succeeded) {
                            if (paymentService.paymentType === "cc") {
                                this.setState({ isSandboxPayment: true })
                            }
                        }
                        else {
                            console.log(json.errors);
                        }
                    })
            }
        }
    }

    submitCreditCard(e) {
        e.preventDefault();

        if (!this.state.isCreditCardLoading) {
            this.exitToast();

            const isFormValid = formService.validate(this.form);
            if (!isFormValid) return;

            this.setState({ isCreditCardLoading: true });

            const creditCardNo = this.form.card.value;
            const expirationMonth = this.form.mm.value;
            const expirationYear = this.form.yyyy.value;
            const securityCode = this.form.cvv.value;

            const creditCard = { creditCardNo, expirationMonth, expirationYear, securityCode };
            paymentService.creditCard = creditCard;

            recaptchaService.getToken("buy")
                .then(token => {
                    let buyRequest = { recaptchaToken: token };

                    paymentService.buyItemCreditCard(buyRequest)
                        .then(json => {
                            if (json.succeeded) {
                                memberService.goToMembers();
                            }
                            else {
                                this.setState({ isCreditCardLoading: false, toastMessage: json.errors[0] });
                                formService.hideAllErrors(this.form);
                                gsap.to(this.toast, {
                                    duration: 0.5,
                                    visibility: 'visible',
                                    opacity: 1,
                                    y: -25,
                                })
                            }
                        })
                })
        }
    }

    submitPayPal(e) {
        e.preventDefault();

        if (!this.state.isPayPalLoading) {
            this.exitToast();
            
            formService.clearAllInputs(this.form);
            this.setState({ isPayPalLoading: true });

            recaptchaService.getToken("buy")
                .then(token => {
                    let buyRequest = { recaptchaToken: token };

                    paymentService.buyItemPayPal(buyRequest)
                        .then(json => {
                            if (json.succeeded) {
                                let token = json.result.orderRequest.paymentRequests[0].token
                                if (token) {
                                    //PayPal payment handler for PayPal
                                    if (paymentService.sandboxTicket) {
                                        window.location.href = `https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&useraction=commit&token=${token}`;
                                    }
                                    else {
                                        window.location.href = `https://www.paypal.com/cgi-bin/webscr?cmd=_express-checkout&useraction=commit&token=${token}`;
                                    }
                                }
                                else {
                                    //Authorize.net payment handler for PayPal
                                    let secureAcceptanceUrl = json.result.orderRequest.paymentRequests[0].secureAcceptanceUrl
                                    if (secureAcceptanceUrl) {
                                        window.location.href = secureAcceptanceUrl;
                                    }
                                }
                            }
                            else {
                                console.log(json.errors);
                                this.setState({ isPayPalLoading: false, toastMessage: 'PayPal payment unsuccessful. Please try again or use another payment method.' });
                                formService.hideAllErrors(this.form);
                                gsap.to(this.toast, {
                                    duration: 0.5,
                                    visibility: 'visible',
                                    opacity: 1,
                                    y: -25,
                                });
                            }
                        })
                });
        }
    }

    exitToast() {
        gsap.to(this.toast, {
            duration: 0.3,
            opacity: 0,
            y: 25,
            onComplete: () => {
                gsap.set(this.toast, {
                    visibility: 'hidden'
                });
            },
        })
    }

    // Component
    render() {
        // Redirect If No Payment Data
        if (!paymentService.item || !paymentService.member) {
            if (paymentService.group) {
                this.props.history.push('/complete-group');
            }
            else {
                this.props.history.push('/buy-register');
            }
        }

        const title = paymentService.item ? paymentService.item.title : '';
        const price = paymentService.item ? (paymentService.item.price * paymentService.item.quantity).toFixed(2) : "";
        const schedule = paymentService.schedule ? `${new Date(paymentService.schedule.startDate).toLocaleDateString("en-US", { weekday: 'long', month: 'short', day: 'numeric' })}, ${new Date(paymentService.schedule.startDate).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" })} - ${new Date(paymentService.schedule.endDate).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" })} (${scheduleService.getTimeZone()})` : null;        

        return (
            <div className={styles['form-container']} ref={ref => this.container = ref}>
                <h1 className={styles['title']}>{title}</h1>
                    {
                        schedule &&
                        <h3 className={styles['schedule']}>{schedule}</h3>
                    }
                    {
                        this.useCreditCard &&
                        <form className={styles['form']} ref={ref => this.form = ref} onSubmit={this.submitCreditCard}>
                            <Input className={styles['cc-input']} type='text' name='card' placeholder='Card No' defaultValue={this.state.isSandboxPayment ? '4007000000027' : null} required />
                            <div className={styles['small-inputs']}>
                                <Input className={styles['small-input']} type='text' name='mm' placeholder='MM' defaultValue={this.state.isSandboxPayment ? '12' : null} required />
                                <Input className={styles['small-input']} type='text' name='yyyy' placeholder='YYYY' defaultValue={this.state.isSandboxPayment ? '2025' : null} required />
                                <Input className={styles['small-input']} type='text' name='cvv' placeholder='CVV' defaultValue={this.state.isSandboxPayment ? '123' : null} required />
                            </div>
                            <PrimaryButton loading={this.state.isCreditCardLoading} whiteSpinner={true} noAnimate={true} scale={1.02} className={styles['buy-button']} type='submit' formNoValidate>Buy Now ${price}</PrimaryButton>
                        </form>
                    }
                    {
                        this.usePayPal &&
                        <>
                            <div className={styles['divider-container']}>
                                <div className={styles['divider']}></div>
                                <span className={styles['or']}>OR</span>
                                <div className={styles['divider']}></div>
                            </div>
                            <PrimaryButton loading={this.state.isPayPalLoading} whiteSpinner={true} noAnimate={true} scale={1.02} className={styles['paypal-button']} onClick={this.submitPayPal}>PayPal</PrimaryButton>
                        </>
                    }
                <div className={styles['terms-container']}>
                    <span className={styles['terms-text']} onClick={this.getSandboxTicket}>By clicking Buy Now you agree to our</span>
                    <Link to='/terms-and-conditions'><span className={styles['terms-button']}>Terms of Use</span></Link>
                </div>
                <Toast className={styles['toast']} error={true} title='Error' description={this.state.toastMessage} onExit={this.exitToast} ref={ref => this.toast = ref} />
            </div>
        )
    }
}

export default withRouter(Payment);