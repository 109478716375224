import React from 'react'
import { Link } from 'react-router-dom'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import image from './images/try-for-free.png'
import PrimaryButton from '../../../shared/Buttons/PrimaryButton/PrimaryButton'

import styles from './TryForFree.module.css'

class TryForFree extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.title = null;
        this.description = null;
        this.image = null;

        // GSAP
        gsap.registerPlugin(ScrollTrigger);
    }

    // Lifecycle 
    componentDidMount() {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 0);

        this.setupAnimations();
    }

    setupAnimations() {
        gsap.from(this.title, {
            scrollTrigger: {
                trigger: this.title,
                start: 'top 90%',
            },
            duration: 0.5,
            y: 25,
            opacity: 0,
        });

        gsap.from(this.description, {
            scrollTrigger: {
                trigger: this.description,
                start: 'top 90%',
            },
            duration: 0.5,
            y: 25,
            opacity: 0,
        });

        gsap.from(this.image, {
            scrollTrigger: {
                trigger: this.image,
                start: 'top 80%',
            },
            duration: 0.8,
            y: 25,
            opacity: 0,
            scale: 0.95,
            ease: 'power1.out'
        });
    }

    // Component
    render() {
        const { header, body } = this.props.data;

        return (
            <section className={styles['content']}>
                <h1 className={styles['title']} ref={ref => this.title = ref}>{header}</h1>
                <p className={styles['description']} ref={ref => this.description = ref}>{body}</p>
                <Link to='/register'><PrimaryButton duration={0.6} zoom={true} scrollTrigger={true} className={styles['button']}>Try for free</PrimaryButton></Link>
                <img src={image} alt="" className={styles['image']} ref={ref => this.image = ref} onLoad={() => ScrollTrigger.refresh()} />
            </section>
        )
    }
}

export default TryForFree;