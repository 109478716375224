import React from 'react'

import styles from './CourseInfo.module.css'

class CourseInfo extends React.Component {
    render() {
        return (
            <div className={styles['content']} ref={this.props.innerRef}>
                <img src={this.props.image} alt='' className={styles['image']} />
                <div className={styles['info']}>
                    <span className={styles['property']}>{this.props.property}</span>
                    <span className={styles['value']}>{this.props.value}</span>
                </div>
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <CourseInfo 
  innerRef={ref} {...props}
/>);