import React from 'react'
import gsap from 'gsap'

// import arrowUp from './images/arrow-up.svg'
import arrowDown from './images/arrow-down.svg'

import styles from './CourseCategoryDropdown.module.css'

class CourseCategoryDropdown extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.icon = null;

        // State
        this.state = {
            currentCategory: null,
            isOpen: this.props.isOpen,
        }

        // This Binding
        this.clickDropdown = this.clickDropdown.bind(this);
        this.clickCategory = this.clickCategory.bind(this);
    }

    // Lifecycle
    componentDidUpdate(previousProps, previousState) {
        if (previousProps.isOpen !== this.props.isOpen) {
            this.setState({ isOpen: this.props.isOpen });
            this.animateIcon(this.props.isOpen);
        }
    }

    // Helpers
    animateIcon(isOpen) {
        if (isOpen) {
            gsap.to(this.icon, {
                duration: 0.3,
                rotate: 180,
                ease: 'back.out',
            })
        }
        else {
            gsap.to(this.icon, {
                duration: 0.3,
                rotate: 0,
                ease: 'back.out',
            })
        }
    }

    // Event Handlers
    clickDropdown(e) {
        e.stopPropagation();
        this.props.onToggle();
    }

    clickCategory(e) {
        e.stopPropagation();
        const category = e.currentTarget.getAttribute('category');
        this.setState({ currentCategory: category }, () => {
            this.props.onToggle();
            this.props.onFilter(category);
        })
    }

    // Component
    render() {
        return (
            <div className={`${styles['content']} ${this.props.className}`}>
                <div className={styles['dropdown']} onClick={this.clickDropdown} ref={this.props.innerRef}>
                    <span className={styles['selection']}>{this.state.currentCategory ? this.state.currentCategory : 'All Categories'}</span>
                    <img src={arrowDown} alt='' className={styles['icon']} ref={ref => this.icon = ref} />
                </div>
                <div className={`${styles['menu']} ${this.state.isOpen ? `${styles['open']}` : `${styles['closed']}`}`}>
                    <div className={styles['categories']}>
                        <div className={styles['category']} onClick={this.clickCategory} category='' key={0}>All Categories</div>
                    {
                        this.props.categories.map((category, categoryIndex) => {
                            return  <div className={styles['category']} onClick={this.clickCategory} category={category} key={categoryIndex+1}>{category}</div>
                        })
                    }
                    </div>
                </div>
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <CourseCategoryDropdown 
  innerRef={ref} {...props}
/>);