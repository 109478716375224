import React from 'react'
import { Link } from 'react-router-dom'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from '../../../../gsap/SplitText'

import membershipImage from './images/membership.svg'
import bundleImage from './images/bundle.svg'
import courseImage from './images/course.svg'
import groupImage from './images/group.svg'
import Plan from './components/Plan/Plan'

import styles from './Plans.module.css'

class Plans extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.draggable = null;
        this.title = null;
        this.plans = [];
        this.dividers = [];

        // State
        this.state = {
            isDown: false,
            startX: 0,
            scrollLeft: 0,
        }
        
        // GSAP
        gsap.registerPlugin(ScrollTrigger);
        gsap.registerPlugin(SplitText);

        // This Binding
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onClickCapture = this.onClickCapture.bind(this);
    }

    // Lifecycle 
    componentDidMount() {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 0);

        this.setupAnimations();
    }

    setupAnimations() {
        var split = new SplitText(this.title, {type: 'lines'});
        gsap.from(split.lines, {
            scrollTrigger: {
                trigger: this.title,
                start: 'top 90%',
            },
            duration: 0.5,
            stagger: 0.15,
            y: 25,
            opacity: 0,
        });

        for (const [index, plan] of this.plans.entries()) {
            gsap.from(plan, {
                scrollTrigger: {
                    trigger: plan,
                    start: 'top 90%',
                },
                duration: 0.5,
                delay: 0.25 * index,
                y: 25,
                opacity: 0,
            });
        }

        for (const [index, divider] of this.dividers.entries()) {
            gsap.from(divider, {
                scrollTrigger: {
                    trigger: divider,
                    start: 'top 90%',
                },
                duration: 0.5,
                delay: 0.15 * index,
                y: 25,
                opacity: 0,
            });
        }
    }

    // Event Handlers
    onMouseDown(e) {
        this.setState({ isDown: true, startX: e.pageX - this.draggable.offsetLeft, scrollLeft: this.draggable.scrollLeft});
        this.draggable.classList.add(styles['dragging']);
    }

    onMouseUp(e) {
        this.setState({ isDown: false });
        this.draggable.classList.remove(styles['dragging']);
    }

    onMouseMove(e) {
        if(!this.state.isDown) return;
        e.preventDefault();
        const x = e.pageX - this.draggable.offsetLeft;
        const move = (x - this.state.startX) * 1; // increase to drag faster
        this.draggable.scrollLeft = this.state.scrollLeft - move;
    }

    onClickCapture(e) {
        if (this.draggable.scrollLeft !== this.state.scrollLeft) {
            // dragging, so prevent click in child
            e.stopPropagation();
            e.preventDefault();
        }
    }

    // Helpers
    getImage(type) {
        switch (type) {
            case 'memberships':
                return membershipImage;
            case 'course-bundles':
                return bundleImage;
            case 'individual-courses':
                return courseImage;
            case 'group-plans':
                return groupImage;
            default:
                return membershipImage;
        }
    }
    
    // Component
    render() {
        const { header, plans } = this.props.data;

        return (
            <section className={styles['content']}>
                <h1 className={styles['title']} ref={ref => this.title = ref}>{header}</h1>
                <div className={styles['plans']} ref={ref => this.draggable = ref} onMouseUp={this.onMouseUp} onMouseDown={this.onMouseDown} onMouseLeave={this.onMouseUp} onMouseMove={this.onMouseMove} onClickCapture={this.onClickCapture}>
                    {
                        plans.map((plan, planIndex) => {
                            return  <React.Fragment key={planIndex}>
                                        <Link to={`/pricing/${plan.type}`}>
                                            <Plan data={plan} image={this.getImage(plan.type)} onClick={this.clickPlan} from={plan.type !== 'memberships'} ref={ref => this.plans[planIndex] = ref} />
                                        </Link>
                                        {
                                            planIndex < plans.length - 1 &&
                                            <div className={styles['divider']} ref={ref => this.dividers[planIndex] = ref}></div>
                                        }
                                    </React.Fragment>
                        })
                    }
                </div>
            </section>
        )
    }
}

export default Plans;