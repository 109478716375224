import React from 'react'
import { Link } from 'react-router-dom'
import gsap from 'gsap'

import image from './images/error.png' // placeholder
import Navbar from '../shared/Navbar/Navbar'
import PrimaryButton from '../shared/Buttons/PrimaryButton/PrimaryButton'
import Subscribe from '../shared/Subscribe/Subscribe'
import Footer from '../shared/Footer/Footer'

import styles from './Error.module.css'

class Error extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.title = null;
        this.image = null;
    }

    // Lifecycle 
    componentDidMount() {
        window.scrollTo(0, 0);
        this.setupAnimations();
    }

    setupAnimations() {        
        gsap.from([this.title, this.image], {
            duration: 0.5,
            delay: 0.3,
            stagger: 0.3,
            y: 25,
            opacity: 0,
        });
    }

    // Component
    render() {
        return (
            <>
                <Navbar />
                <div className={styles['background']}>
                    <section className={styles['content']}>
                        <h1 className={styles['title']} ref={ref => this.title = ref}>Page not found</h1>
                        <Link to='/'><PrimaryButton zoom={true} delay={0.15} duration={0.5} className={styles['button']}>Back to home</PrimaryButton></Link>
                        <img className={styles['image']} src={image} alt='' ref={ref => this.image = ref} />
                    </section>
                    <Subscribe />
                    <Footer />
                </div>
            </>
        )
    }
}

export default Error;