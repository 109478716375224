import React from 'react'

import ArrowButton from '../../../../../shared/Buttons/ArrowButton/ArrowButton'

import styles from './AllCoursesButton.module.css'

class AllCoursesButton extends React.Component {
    render() {
        const text = this.props.text ? this.props.text : 'View All Courses'
        
        return (
            <div className={`${styles['content']} ${this.props.className}`} ref={this.props.innerRef} onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave}>
                <p className={styles['title']}>{text}</p>
                <ArrowButton className={styles['arrow']} />
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <AllCoursesButton 
  innerRef={ref} {...props}
/>);