import React from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from '../../gsap/SplitText'

import bootstrapService from '../../services/BootstrapService'

import Navbar from '../shared/Navbar/Navbar'
import ApprovalCard from './components/ApprovalCard/ApprovalCard'
import CourseApprovalCard from './components/CourseApprovalCard/CourseApprovalCard'
import Subscribe from '../shared/Subscribe/Subscribe'
import Footer from '../shared/Footer/Footer'

import styles from './Approvals.module.css'

class Approvals extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.title = null;
        this.divider = null;
        this.approvals = [];
        this.courseTitle = [];
        this.courseApprovals = [];

        // GSAP
        gsap.registerPlugin(ScrollTrigger);
        gsap.registerPlugin(SplitText);

        // This Binding
        this.animateApprovalEnter = this.animateApprovalEnter.bind(this);
        this.animateApprovalLeave = this.animateApprovalLeave.bind(this);
    }

    // Lifecycle 
    componentDidMount() {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 0);

        window.scrollTo(0, 0);
        this.setupAnimations();
    }

    setupAnimations() {
        let title = new SplitText(this.title, {type: 'lines'});
        let elements = title.lines;
        if (window.innerWidth >= 768) {
            elements.push(this.divider);
        }
        
        elements.push(this.approvals.slice(0, 2));
        gsap.from(elements, {
            duration: 0.5,
            delay: 0.3,
            stagger: 0.25,
            y: 25,
            opacity: 0,
        });

        for (const [index, approval] of this.approvals.slice(2, this.approvals.length).entries()) {
            gsap.from(approval, {
                scrollTrigger: {
                    trigger: approval,
                    start: 'top 90%',
                },
                duration: 0.5,
                delay: window.innerWidth >= 768 && index % 2 !== 0 ? 0.25 : 0,
                y: 25,
                opacity: 0,
            });
        }

        let courseTitle = new SplitText(this.courseTitle, {type: 'lines'});
        gsap.from(courseTitle.lines, {
            scrollTrigger: {
                trigger: this.courseTitle,
                start: 'top 90%',
            },
            duration: 0.5,
            stagger: 0.15,
            y: 25,
            opacity: 0,
        });

        for (const [index, approval] of this.courseApprovals.entries()) {
            gsap.from(approval, {
                scrollTrigger: {
                    trigger: approval,
                    start: 'top 90%',
                },
                duration: 0.5,
                delay: window.innerWidth >= 1200 && index === 0 ? 0.25 : 0,
                y: 25,
                opacity: 0,
            });
        }
    }

    // Event Handlers
    animateApprovalEnter(index) {
        if (window.innerWidth >= 768) {
            gsap.to(this.approvals[index], {
                translateY: -10,
            });
        }
    }

    animateApprovalLeave(index) {
        if (window.innerWidth >= 768) {
            gsap.to(this.approvals[index], {
                translateY: 0,
            });
        }
    }

    // Component
    render() {
        const data =  bootstrapService.data.landing.pages.find(p => p.name === "approvals");

        const header = data ? data.header : null;
        const orgApprovals = data ? data.organizationApprovals : null;
        const courseApprovals = data ? data.courseApprovals : null;

        return (
            <>
                <Navbar active='approvals' />
                <div className={styles['background']}>
                    <section className={styles['content']}>
                        <h1 className={styles['title']} ref={ref => this.title = ref}>{header}</h1>
                        <div className={styles['divider']} ref={ref => this.divider = ref}></div>
                        <div className={styles['approvals']}>
                            {
                                orgApprovals.map((approval, approvalIndex) => {
                                    return  <ApprovalCard data={approval} index={approvalIndex} key={approvalIndex} ref={ref => this.approvals[approvalIndex] = ref} onMouseEnter={(index) => this.animateApprovalEnter(index)} onMouseLeave={(index) => this.animateApprovalLeave(index)} />    
                                })
                            }
                        </div>
                        {
                            courseApprovals &&
                            <div className={styles['course-container']}>
                                <h1 className={styles['course-title']} ref={ref => this.courseTitle = ref}>Course Specific Approvals</h1>
                                <div className={styles['course-approvals']}>
                                    {
                                        courseApprovals.map((approval, approvalIndex) => {
                                            return <CourseApprovalCard data={approval} key={approvalIndex} ref={ref => this.courseApprovals[approvalIndex] = ref} />
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </section>
                    <Subscribe />
                    <Footer />
                </div>
            </>
        )
    }
}

export default Approvals;