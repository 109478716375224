import React from 'react'

import ArrowButton from '../../../../../shared/Buttons/ArrowButton/ArrowButton'

import styles from './ApprovalsButton.module.css'

class ApprovalsButton extends React.Component {
    render() {
        return (
            <div className={`${styles['content']} ${this.props.className}`} ref={this.props.innerRef} onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave}>
                <h3 className={styles['title']}>Accredited and Approved Nationwide</h3>
                <ArrowButton className={styles['arrow']} />
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <ApprovalsButton 
  innerRef={ref} {...props}
/>);