import authenticateImage from '../components/shared/Modal/images/authenticate.png'
import authenticate2Image from '../components/shared/Modal/images/authenticate2.png'
import membershipImage from '../components/shared/Modal/images/membership.png'
import courseImage from '../components/shared/Modal/images/course.png'
import bundleImage from '../components/shared/Modal/images/bundle.png'
import faqImage from '../components/shared/Modal/images/faq.png'
import heroImage from '../components/Home/components/Hero/images/hero.png'
import hero2Image from '../components/Home/components/Hero/images/hero2.png'

class ImagePreloadService {
    constructor() {
        this.images = [
            { name: authenticateImage, url: authenticateImage },
            { name: authenticate2Image, url: authenticate2Image },
            { name: membershipImage, url: membershipImage },
            { name: courseImage, url: courseImage },
            { name: bundleImage, url: bundleImage },
            { name: faqImage, url: faqImage },
            { name: heroImage, url: heroImage },
            { name: hero2Image, url: hero2Image },
            // Add more images as needed
        ];
        this.imageCache = {};
    }

    // Loads images and stores them as base64 data URIs in the cache
    loadImages() {
        for (const image of this.images) {
            fetch(image.url)
                .then((response) => response.blob())
                .then((blob) => {
                    let url = URL.createObjectURL(blob);
                    this.imageCache[image.name] = url;
                });
        }
    }

    // Retrieves the image source; if cached, returns the data URI, otherwise the URL
    getImageSrc(name) {
        if (this.imageCache[name]) {
            return this.imageCache[name];
        } else {
            const found = this.images.find(image => image.name === name);
            return found ? found.url : null; // return null if the image is not found
        }
    }
}

const imagePreloadService = new ImagePreloadService();
export default imagePreloadService;