import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import gsap from 'gsap'

import formService from '../../services/FormService'
import memberService from '../../services/MemberService'
import recaptchaService from '../../services/RecaptchaService'

import PrimaryButton from '../shared/Buttons/PrimaryButton/PrimaryButton'
import Input from '../shared/Input/Input'
import Toast from '../shared/Toast/Toast'

import styles from './ResetPassword.module.css'

class ResetPassword extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.container = null;
        this.form = null;
        this.toast = null;

        // State
        this.state = {
            isFormLoading: false,
            toastMessage: null,
        }

        // This Binding
        this.submitForm = this.submitForm.bind(this);
        this.exitToast = this.exitToast.bind(this);
    }

    // Lifecycle
    componentDidMount() {
        this.setupAnimations();
    }

    // Helpers
    setupAnimations() {
        gsap.from(this.container, {
            duration: 0.5,
            opacity: 0,
        })
    }

    // Event Handlers
    submitForm(e) {
        e.preventDefault();

        if (!this.state.isFormLoading) {
            this.exitToast();

            const isFormValid = formService.validate(this.form);

            const token = this.props.match.params.token;
            const password = e.target.password.value;
            const confirm = e.target.confirm.value;

            if (password !== confirm) {
                formService.showInputError(this.form, "confirm", "This password does not match");
                return;
            }

            if(!isFormValid) return;

            this.setState({ isFormLoading: true });

            let resetPasswordRequest = {token, password}

            recaptchaService.getToken("resetPassword")
            .then(token => {
                resetPasswordRequest.recaptchaToken = token;

                memberService.resetPassword(resetPasswordRequest)
                .then(json => {
                    if (json.succeeded) {
                        memberService.goToMembers();
                    }
                    else {
                        this.setState({ isFormLoading: false, toastMessage: json.errors[0] });
                        formService.hideAllErrors(this.form);
                        gsap.to(this.toast, {
                            duration: 0.5,
                            visibility: 'visible',
                            opacity: 1,
                            y: -25,
                        })
                    }
                });
            });
        }
    }

    exitToast() {
        gsap.to(this.toast, {
            duration: 0.3,
            opacity: 0,
            y: 25,
            onComplete: () => {
                gsap.set(this.toast, {
                    visibility: 'hidden'
                });
            },
        })
    }

    // Component
    render() {
        return (
            <div className={styles['form-container']} ref={ref => this.container = ref}>
                <h1 className={styles['title']}>Reset Password</h1>
                <form className={styles['form']} ref={ref => this.form = ref} onSubmit={this.submitForm}>
                    <Input className={styles['password-input']} type='password' name='password' placeholder='Password' required />
                    <Input className={styles['confirm-input']} type='password' name='confirm' placeholder='Confirm Password' required />
                    <PrimaryButton loading={this.state.isFormLoading} whiteSpinner={true} noAnimate={true} scale={1.02} className={styles['reset-button']} type='submit' formNoValidate>Reset password</PrimaryButton>
                </form>
                <div className={styles['login-container']}>
                    <span className={styles['login-text']}>Already have an account?</span>
                    <Link to='/login'><span className={styles['login-button']}>Login</span></Link>
                </div>
                <Toast className={styles['toast']} error={true} title='Error' description={this.state.toastMessage} onExit={this.exitToast} ref={ref => this.toast = ref} />
            </div>
        )
    }
}

export default withRouter(ResetPassword);