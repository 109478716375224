import React from 'react'

import ArrowButton from '../../../shared/Buttons/ArrowButton/ArrowButton';

import styles from './ReadMoreButton.module.css'

class ReadMoreButton extends React.Component {
    // Component
    render() {
        return (
            <div className={`${styles['content']} ${this.props.className}`} ref={this.props.innerRef} onClick={this.props.onClick} onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave}>
                <span className={styles['text']}>{this.props.isOpen ? 'Read Less' : 'Read More'}</span>
                <ArrowButton className={`${styles['arrow']} ${this.props.isOpen ? styles['open'] : ''}`} />
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <ReadMoreButton 
  innerRef={ref} {...props}
/>);