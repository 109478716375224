import React from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from '../../../../gsap/SplitText'

import image1 from './images/1.png'
import image2 from './images/2.png'
import image3 from './images/3.png'
import image4 from './images/4.png'
import HowItWorksCard from './components/HowItWorksCard/HowItWorksCard'

import styles from './HowItWorks.module.css'

class HowItWorks extends React.Component {
    
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.draggable = null;
        this.title = null;
        this.cards = [];

        // State
        this.state = {
            isDown: false,
            startX: 0,
            scrollLeft: 0,
        }

        // GSAP
        gsap.registerPlugin(ScrollTrigger);
        gsap.registerPlugin(SplitText);

        // This Binding
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.animateCardEnter = this.animateCardEnter.bind(this);
        this.animateCardLeave = this.animateCardLeave.bind(this);
    }

    // Lifecycle 
    componentDidMount() {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 0);

        this.setupAnimations();
    }
    
    setupAnimations() {
        if (window.innerWidth >= 1200) {
            var split = new SplitText(this.title, {type: 'lines'});
            gsap.from(split.lines, {
                scrollTrigger: {
                    trigger: this.title,
                    start: 'top 80%',
                },
                duration: 0.5,
                stagger: 0.15,
                y: 25,
                opacity: 0,
            });

            gsap.from(this.draggable, {
                scrollTrigger: {
                    trigger: this.draggable,
                    start: 'top 50%',
                },
                duration: 0.8,
                x: 55,
                opacity: 0,
            });
        }
        else {
            gsap.from(this.title, {
                scrollTrigger: {
                    trigger: this.title,
                    start: 'top 90%',
                },
                duration: 0.5,
                y: 25,
                opacity: 0,
            });
            
            for (const card of this.cards) {
                gsap.from(card, {
                    scrollTrigger: {
                        trigger: card,
                        start: 'top 90%',
                    },
                    duration: 0.5,
                    y: 25,
                    opacity: 0,
                });
            }
        }
    }

    // Event Handlers
    onMouseDown(e) {
        this.setState({ isDown: true, startX: e.pageX - this.draggable.offsetLeft, scrollLeft: this.draggable.scrollLeft});
        this.draggable.classList.add(styles['dragging']);
    }

    onMouseUp(e) {
        this.setState({ isDown: false });
        this.draggable.classList.remove(styles['dragging']);
    }

    onMouseMove(e) {
        if(!this.state.isDown) return;
        e.preventDefault();
        const x = e.pageX - this.draggable.offsetLeft;
        const move = (x - this.state.startX) * 1; // increase to drag faster
        this.draggable.scrollLeft = this.state.scrollLeft - move;
    }

    animateCardEnter(index) {
        if (window.innerWidth >= 1200) {
            gsap.to(this.cards[index], {
                duration: 0.5,
                scale: 1.02,
            });
        }
    }

    animateCardLeave(index) {
        if (window.innerWidth >= 1200) {
            gsap.to(this.cards[index], {
                duration: 0.5,
                scale: 1,
            });
        }
    }

    // Component
    render() {
        const { header, works } = this.props.data;
        const images = [image1, image2, image3, image4]

        return (
            <section className={styles['content']} ref={ref => this.draggable = ref} onMouseDown={this.onMouseDown} onMouseUp={this.onMouseUp} onMouseLeave={this.onMouseUp} onMouseMove={this.onMouseMove}>
                <h1 className={styles['title']} ref={ref => this.title = ref}>{header}</h1>
                <div className={styles['cards']}>
                    {
                        works.map((work, workIndex) => {
                            return <HowItWorksCard index={workIndex} number={workIndex+1} image={images[workIndex]} data={work} className={styles[`card-${workIndex+1}`]} ref={ref => this.cards[workIndex] = ref} key={workIndex} onMouseEnter={(index) => this.animateCardEnter(index)} onMouseLeave={(index) => this.animateCardLeave(index)}/>
                        })
                    }
                    <div className={styles['empty']}></div>
                </div>
            </section>
        )
    }
}

export default HowItWorks;