import React from 'react'
import gsap from 'gsap'

import formService from '../../services/FormService'
import faqService from '../../services/FaqService'
import recaptchaService from '../../services/RecaptchaService'

import PrimaryButton from '../shared/Buttons/PrimaryButton/PrimaryButton'
import Input from '../shared/Input/Input'
import TextArea from '../shared/TextArea/TextArea'
import Toast from '../shared/Toast/Toast'

import styles from './AskQuestion.module.css'

class AskQuestion extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.container = null;
        this.form = null;
        this.successMessage = null;

        // State
        this.state = {
            isFormLoading: false,
            isFormError: false,
            toastMessage: null,
        }

        // This Binding
        this.submitQuestion = this.submitQuestion.bind(this);
        this.exitToast = this.exitToast.bind(this);
    }

    // Lifecycle
    componentDidMounted() {
        this.setupAnimations();
    }

    // Helpers
    setupAnimations() {
        gsap.from(this.container, {
            duration: 0.5,
            opacity: 0,
        });
    }

    // Event Handlers
    submitQuestion(e) {
        e.preventDefault();

        if (!this.state.isFormLoading) {
            this.exitToast();

            const isFormValid = formService.validate(this.form);
            if(!isFormValid) return;
    
            this.setState({ isFormLoading: true });
    
            const email = e.target.email.value.trim();
            const question = e.target.question.value;
    
            let faqRequest = {email, question};
    
            recaptchaService.getToken("askFaq")
            .then(token => {
                faqRequest.recaptchaToken = token;
    
                faqService.askQuestion(faqRequest)
                .then(json => {
                    if (json.succeeded) {
                        // Success
                        this.setState({ isFormLoading: false, isFormError: false, toastMessage: 'Thank you for the question! We will answer you shortly.' });
                        formService.hideAllErrors(this.form);
                        e.target.email.value = '';
                        e.target.question.value = '';
                        gsap.to(this.toast, {
                            duration: 0.5,
                            visibility: 'visible',
                            opacity: 1,
                            y: -25,
                        })
                    }
                    else {
                        this.setState({ isFormLoading: false, isFormError: true, toastMessage: json.errors[0] });
                        formService.hideAllErrors(this.form);
                        gsap.to(this.toast, {
                            duration: 0.5,
                            visibility: 'visible',
                            opacity: 1,
                            y: -25,
                        })
                    }
                });
            });
        }
    }

    exitToast() {
        gsap.to(this.toast, {
            duration: 0.3,
            opacity: 0,
            y: 25,
            onComplete: () => {
                gsap.set(this.toast, {
                    visibility: 'hidden'
                });
            },
        })
    }
    
    // Component
    render() {
        return (
            <div className={styles['form-container']} ref={ref => this.container = ref}>
                <h1 className={styles['title']}>Ask a question</h1>
                <form className={styles['form']} ref={ref => this.form = ref} onSubmit={this.submitQuestion}>
                    <Input className={styles['email-input']} type='email' name='email' placeholder='E-mail' required />
                    <TextArea className={styles['question-input']} type='text' name='question' placeholder='Question' required />
                    <PrimaryButton loading={this.state.isFormLoading} whiteSpinner={true} whiteBubble={true} noAnimate={true} scale={1.02} className={styles['submit-button']} type='submit' formNoValidate>Submit</PrimaryButton>
                </form>
                <Toast className={styles['toast']} error={this.state.isFormError} title={this.state.isFormError ? 'Error' : 'Thank you'} description={this.state.toastMessage} onExit={this.exitToast} ref={ref => this.toast = ref} />
            </div>
        )
    }
}

export default AskQuestion;