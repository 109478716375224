import React from 'react'
import gsap from 'gsap'

import ArrowButton from '../../../shared/Buttons/ArrowButton/ArrowButton'

import styles from './ApprovalCard.module.css'

class ApprovalCard extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.arrow = null;

        // This Binding
        this.animateApprovalEnter = this.animateApprovalEnter.bind(this);
        this.animateApprovalLeave = this.animateApprovalLeave.bind(this);
    }

    // Event Handlers
    animateApprovalEnter() {
        // this.props.onMouseEnter(this.props.index);
        gsap.to(this.arrow, {
            duration: 0.5,
            scale: 1.05,
        })
    }

    animateApprovalLeave() {
        // this.props.onMouseLeave(this.props.index);
        gsap.to(this.arrow, {
            duration: 0.5,
            scale: 1,
        })
    }

    // Component
    render() {
        const { url, imageUrl, header, body } = this.props.data;

        return (
            <a href={url} target="_blank" rel="noopener noreferrer" className={styles['link']} onMouseEnter={this.animateApprovalEnter} onMouseLeave={this.animateApprovalLeave}>
                <div className={styles['content']} ref={this.props.innerRef}>
                    <img className={styles['image']} src={imageUrl} alt='' />
                    <h1 className={styles['title']}>{header}</h1>
                    <p className={styles['description']}>{body}</p>
                    <ArrowButton className={styles['button']} ref={ref => this.arrow = ref} />
                </div>
            </a>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <ApprovalCard 
  innerRef={ref} {...props}
/>);