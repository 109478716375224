import React from 'react'
import gsap from 'gsap'

import image from './images/error.png' // placeholder

import styles from './AppError.module.css'

class AppError extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.title = null;
        this.subtitle = null;
        this.description = null;
    }

    // Lifecycle 
    componentDidMount() {
        window.scrollTo(0, 0);
        this.setupAnimations();
    }

    setupAnimations() {        
        gsap.from([this.title, this.subtitle, this.description], {
            duration: 0.5,
            delay: 0.3,
            stagger: 0.3,
            y: 25,
            opacity: 0,
        });
    }

    // Component
    render() {
        return (
            <section className={styles['content']}>
                <h1 className={styles['title']} ref={ref => this.title = ref}>Error</h1>
                <h3 className={styles['subtitle']} ref={ref => this.subtitle = ref}>App Failed</h3>
                <p className={styles['description']} ref={ref => this.description = ref}>The application failed to initialize.<br></br>Please contact customer support for assistance.<br></br>Thank you.</p>
            </section>
        )
    }
}

export default AppError;