import React from 'react'

import error from './images/error.svg'

import styles from './TextArea.module.css'

class TextArea extends React.Component {
    constructor(props) {
        super(props);

        // Refs
        this.input = null;

        // This Binding
        this.clickInput = this.clickInput.bind(this);
    }

    // Event Handlers
    clickInput() {
        this.input.focus();
    }

    // Component
    render() {
        return (
            <div className={`${styles['content']} ${this.props.className} a4-input`} onClick={this.clickInput}>
                <textarea ref={ref => this.input = ref} className={styles['text']} type={this.props.type} name={this.props.name} placeholder={this.props.placeholder} required={this.props.required} />
                <span className={`${styles['error']} a4-input-error`}>This is an error</span>
                <img src={error} alt='' className={styles['image']} />
            </div>
        )
    }
}

export default TextArea;