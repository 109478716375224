import React from 'react'

import Input from '../Input/Input'

import styles from './FullNameInput.module.css'

class FullNameInput extends React.Component {
    constructor(props) {
        super(props);

        // Refs
        this.fullNameInput = null;
        this.firstNameInput = null;
        this.middleNameInput = null;
        this.lastNameInput = null;

        // State
        this.state = {
            useMiddleName: false,
            firstName: this.props.firstName,
            middleName: this.props.middleName,
            lastName: this.props.lastName,
        }

        // This Binding
        this.checkFullNameInput = this.checkFullNameInput.bind(this);
    }

    // Lifecycle
    componentDidMount() {
        this.setupInputs();
    }

    // Event Handlers
    checkFullNameInput(e) {
        if (e.currentTarget.value.split(" ").length > 2) {
            // Update State and Focus Last Name
            this.setState({
                useMiddleName: true,
                firstName: e.currentTarget.value.split(" ")[0],
                middleName: e.currentTarget.value.split(" ")[1],
            }, () => {
                // Update Parent Component (Form)
                this.props.useMiddleName();
            });
        }
    }

    // Helpers
    setupInputs() {
        // Timeout hack ensures function runs after Render (so that REFs are set)
        setTimeout(() => {
            if (this.state.firstName && this.state.middleName && this.state.lastName) {
                // Update State
                this.setState({ useMiddleName: true }, () => {
                    // Update Parent Component (Form)
                    this.props.useMiddleName();
                });
            }
        }, 0);
    }

    getFullName() {
        if (this.state.firstName && this.state.middleName && this.state.lastName) {
            return this.state.firstName + " " + this.state.middleName + " " + this.state.lastName;
        }
        else if (this.state.firstName && this.state.lastName) {
            return this.state.firstName + " " + this.state.lastName
        }
        else {
            return "";
        }
    }

    // Component
    render() {
       return (
            <>
                {
                    !this.state.useMiddleName &&
                    <Input className={`${styles['full-name']} ${this.props.className}`} ref={ref => this.fullNameInput = ref} type='text' name='fullName' placeholder='Full Name' defaultValue={this.getFullName} onChange={this.checkFullNameInput} required />
                }
                {
                    this.state.useMiddleName &&
                    <div className={styles['separate-names']}>
                        <Input className={`${styles['small-input']} ${this.props.className}`} ref={ref => this.firstNameInput = ref} type='text' name='firstName' placeholder='First Name' defaultValue={this.state.firstName} required/>
                        <Input className={`${styles['small-input']} ${this.props.className}`} ref={ref => this.middleNameInput = ref} type='text' name='middleName' placeholder='Middle Name' defaultValue={this.state.middleName}/>
                        <Input className={`${styles['small-input']} ${this.props.className}`} ref={ref => this.lastNameInput = ref} focusInput type='text' name='lastName' placeholder='Last Name' defaultValue={this.state.lastName} required/>
                    </div>
                }
            </>
        )
    }
}

export default FullNameInput;