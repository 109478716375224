import React from 'react'
import { debounce } from '../../../../utils/debounce'
import gsap from 'gsap'

import arrowDown from './images/arrow-down.svg'

import styles from './AuthorityDropdown.module.css'

class AuthorityDropdown extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs

        // State
        this.state = {
            isOpen: false,
            searchFilter: null
        }

        // This Binding
        this.clickDropdown = this.clickDropdown.bind(this);
        this.clickAuthority = this.clickAuthority.bind(this);
        this.searchAuthority = debounce(this.searchAuthority.bind(this), 400);
    }

    // Lifecycle
    componentDidUpdate(previousProps, previousState) {
        // When Parent Props Change We Need To Trigger Reload
        if (previousProps.isOpen !== this.props.isOpen) {
            this.setState({ isOpen: this.props.isOpen });
            this.animateIcon(this.props.isOpen);
        }
    }

    // Event Handlers
    animateIcon(isOpen) {
        if (isOpen) {
            gsap.to(this.icon, {
                duration: 0.3,
                rotate: 180,
                ease: 'back.out',
            })
        }
        else {
            gsap.to(this.icon, {
                duration: 0.3,
                rotate: 0,
                ease: 'back.out',
            })
        }
    }

    clickDropdown(e) {
        e.stopPropagation();
        this.props.onToggle();
    }

    clickAuthority(e) {
        e.stopPropagation();
        const authority = e.currentTarget.getAttribute('authority');
        this.props.changeAuthority(authority);
        this.props.onToggle();
 
    }

    searchAuthority(e) {
        e.stopPropagation();
        this.setState({ searchFilter: e.target.value !== "" ? e.target.value.toLowerCase() : null });
    }

    // Component
    render() {
        let allRequirements = this.props.requirements
        let requirements = this.state.searchFilter ? allRequirements.filter(r => r.authorityName.toLowerCase().search(this.state.searchFilter) > -1) : allRequirements;

        return (
            <div className={`${styles['content']} ${this.props.className}`} ref={this.props.innerRef}>
                <div className={styles['dropdown']} onClick={this.clickDropdown}>
                    <span className={styles['selection']}>{this.props.currentAuthority}</span>
                    <img src={arrowDown} alt='' className={styles['icon']} ref={ref => this.icon = ref} />
                </div>
                <div className={`${styles['menu']} ${this.state.isOpen ? `${styles['open']}` : `${styles['closed']}`}`}>
                    <input className={styles['input']} onChange={this.searchAuthority} onClick={(e) => e.stopPropagation()} type='search' placeholder='Search' />
                    <div className={styles['results']}>
                    {
                        requirements.map((requirement, requirementIndex) => {
                            return  <div className={styles['result']} key={requirementIndex} onClick={this.clickAuthority} authority={requirement.authorityName}>{requirement.authorityName}</div>
                        })
                    }
                    </div>
                </div>
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <AuthorityDropdown 
  innerRef={ref} {...props}
/>);