import React from 'react'

import styles from './Spinner.module.css'

class Spinner extends React.Component {
    render() {
        return (
            <div className={`${styles['spinner']} ${this.props.className} ${this.props.white ? styles['white'] : ''}`} aria-hidden={true}></div>
        )
    }
}

export default Spinner;