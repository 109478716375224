import React from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import Spinner from '../../Spinner/Spinner'

import styles from './PrimaryButton.module.css'

class PrimaryButton extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.button = null;
        this.text = null;
        this.bubble = null;

        // GSAP
        gsap.registerPlugin(ScrollTrigger);

        // This Binding
        this.mouseEnter = this.mouseEnter.bind(this);
        this.mouseLeave = this.mouseLeave.bind(this);
    }

    // Lifecycle 
    componentDidMount() {
        if (!this.props.noAnimate) {
            setTimeout(() => {
                ScrollTrigger.refresh();
            }, 0);
    
            this.setupAnimations();
        }
    }

    setupAnimations() {
        gsap.from(this.text, {
            ...(this.props.scrollTrigger && { scrollTrigger: {
                trigger: this.button,
                start: 'top 90%',
            } }),
            duration: this.props.duration ? this.props.duration : 0.5,
            delay: this.props.delay,
            opacity: 0,
            ease: 'power4.in'
        })

        gsap.from(this.button, {
            ...(this.props.scrollTrigger && { scrollTrigger: {
                trigger: this.button,
                start: 'top 90%',
            } }),
            duration: this.props.duration ? this.props.duration - 0.1 : 0.4,
            delay: this.props.delay + 0.1,
            ...(this.props.expand && { width: window.innerWidth < 768 ? 100 : 150 }),
            ...(this.props.zoom && { scale: 0.4 }),
            opacity: 0,
            ease: 'power1.out'
        });
    }

    // Event Handlers
    mouseEnter(e) {        
        gsap.to(this.button, {
            duration: 0.3,
            scale: this.props.scale ? this.props.scale : 1.03,
        })

        // // Open Bubble
        // var parentOffset = this.button.getBoundingClientRect();
        // console.log(parentOffset)
        // var relX = e.pageX - parentOffset.left;
        // var relY = e.pageY - parentOffset.top;
        // console.log(relX)
        // console.log(relY)

        // this.bubble.style.left = relX;
        // this.bubble.style.top = relY;
        // this.bubble.classList.remove(styles['close-bubble']);
        // this.bubble.classList.add(styles['open-bubble']);
    }

    mouseLeave(e) {
        gsap.to(this.button, {
            duration: 0.3,
            scale: 1,
        })

        // // Close Bubble
        // var parentOffset = this.button.getBoundingClientRect();
        // console.log(parentOffset)
        // var relX = e.pageX - parentOffset.left;
        // var relY = e.pageY - parentOffset.top;
        // console.log(relX)
        // console.log(relY)

        // this.bubble.style.left = relX;
        // this.bubble.style.top = relY;
        // this.bubble.classList.remove(styles['open-bubble']);
        // this.bubble.classList.add(styles['close-bubble']);
    }

    // Component
    render() {
        return (
            <button className={`${styles['button']} ${this.props.className}`} onClick={this.props.onClick} type={this.props.type} formNoValidate={this.props.formNoValidate} ref={ref => this.button = ref} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
                <span className={styles['bubble']} ref={ref => this.bubble = ref}></span>
                {
                    !this.props.loading &&
                    <span className={styles['text']} ref={ref => this.text = ref}>{this.props.children}</span>
                }
                {
                    this.props.loading &&
                    <Spinner className={styles['spinner']} white={this.props.whiteSpinner} />
                }
            </button>
        )
    }
}

export default PrimaryButton;

// // Need to forward the ref to the compoent so that parent components can access the inner ref 
// export default React.forwardRef((props, ref) => <PrimaryButton 
//   innerRef={ref} {...props}
// />);

