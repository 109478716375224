import React from 'react'

// import Component from './components/Component/Component'

import styles from './PricingTab.module.css'

class PricingTab extends React.Component {
    render() {
        return (
            <button className={`${styles['button']} ${this.props.selected ? styles['selected'] : ''}`} plan={this.props.plan} onClick={this.props.onClick}>
                {this.props.children}
            </button>
        )
    }
}

export default PricingTab;