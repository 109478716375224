import React from 'react'
import { Link } from 'react-router-dom'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from '../../../../gsap/SplitText'
import imagePreloadService from '../../../../services/ImagePreloadService'

import PrimaryButton from '../../../shared/Buttons/PrimaryButton/PrimaryButton'
import ApprovalsButton from './components/ApprovalsButton/ApprovalsButton'
import heroImage from './images/hero.png'
import hero2Image from './images/hero2.png'

import styles from './Hero.module.css'

class Hero extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.title = null;
        this.button = null;
        this.image = null;
        this.approvals = null;

        // Vars
        this.approvalsAnimation = null;

        // GSAP
        gsap.registerPlugin(ScrollTrigger);
        gsap.registerPlugin(SplitText);

        // This Binding
        this.animateApprovalsEnter = this.animateApprovalsEnter.bind(this);
        this.animateApprovalsLeave = this.animateApprovalsLeave.bind(this);
    }

    // Lifecycle 
    componentDidMount() {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 0);

        this.setupAnimations();
    }

    // Helpers
    setupAnimations() {
        var split = new SplitText(this.title, {type: 'lines'});
        gsap.from(split.lines, {
            duration: 1,
            delay: 0.4,
            stagger: 0.2,
            y: 25,
            opacity: 0,
            ease: 'power1.out'
        });

        gsap.from(this.image, {
            duration: 0.8,
            delay: 1.1,
            y: 15,
            opacity: 0,
            scale: 0.95
        });

        this.approvalsAnimation = gsap.from(this.approvals, {
            scrollTrigger: {
                trigger: this.approvals,
                start: 'top 80%',
            },
            duration: 0.6,
            x: 25,
            opacity: 0,
        });
    }

    // Event Handlers
    animateApprovalsEnter() {
        if (this.approvalsAnimation.totalProgress() === 1) {
            gsap.to(this.approvals, {
                translateX: 5,
            });
        }
    }

    animateApprovalsLeave() {
        if (this.approvalsAnimation.totalProgress() === 1) {
            gsap.to(this.approvals, {
                translateX: 0,
            });
        }
    }

    // Component
    render() {
        const { header, actionLabel, actionLink } = this.props.data;
        const image = imagePreloadService.getImageSrc(heroImage);

        return (
            <section className={styles['content']}>
                <h1 className={styles['title']} ref={ref => this.title = ref}>{header}</h1>
                <Link to={actionLink ? actionLink : ''}>
                    <PrimaryButton duration={0.6} delay={0.7} expand={true} className={styles['button']}>{actionLabel}</PrimaryButton>
                </Link>
                <img src={image} alt='' className={styles['image']} ref={ref => this.image = ref} onLoad={() => ScrollTrigger.refresh()} />
                <Link to='/approvals'>
                    <ApprovalsButton className={styles['approvals']} ref={ref => this.approvals = ref} onMouseEnter={this.animateApprovalsEnter} onMouseLeave={this.animateApprovalsLeave} />
                </Link>
            </section>
        )
    }
}

export default Hero;