import React from 'react'

import styles from './Testimonial.module.css'

class Testimonial extends React.Component {
    render() {
        const { name, location, comment, imageUrl } = this.props.data;

        return (
            <div className={`${styles['content']} ${this.props.className}`} ref={this.props.innerRef}>
                <div className={styles['top']}>
                    <img src={imageUrl} alt='' className={styles['image']} />
                    <div className={styles['info']}>
                        <h3 className={styles['name']}>{name}</h3>
                        <span className={styles['location']}>{location}</span>
                    </div>
                </div>
                <p className={styles['testimonial']}>{comment}</p>
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <Testimonial 
  innerRef={ref} {...props}
/>);