import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import gsap from 'gsap'

import bootstrapService from '../../services/BootstrapService'
import formService from '../../services/FormService'
import memberService from '../../services/MemberService'
import recaptchaService from '../../services/RecaptchaService'

import PrimaryButton from '../shared/Buttons/PrimaryButton/PrimaryButton'
import Input from '../shared/Input/Input'
import Toast from '../shared/Toast/Toast'

import styles from './CompleteProfile.module.css'

class CompleteProfile extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.form = null;
        this.toast = null;

        // State
        this.state = {
            isFormLoading: false,
            useMiddleName: false,
            requireFirstName: true,
            requireLastName: true,
            requirePassword: true,
            email: null,
            firstName: null,
            middleName: null,
            lastName: null,
            error: 'Your account could not be verified.',
            toastMessage: null,
        }

        // This Binding
        this.useMiddleName = this.useMiddleName.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.exitToast = this.exitToast.bind(this);
    }

    // Lifecycle
    componentDidMount() {
        this.setupAnimations();
        this.setBootstrapCallback();
    }

    // Helpers
    setupAnimations() {
        gsap.from(this.container, {
            duration: 0.5,
            opacity: 0,
        })
    }

    setBootstrapCallback() {
        bootstrapService.setCallback(() => {
            return this.validateToken();
        })
    }

    validateToken() {
        const token = this.props.match.params.token;

        return memberService.validateCompleteProfileToken(token)
        .then(json => {
            if (json.succeeded) {
                if (json.result.status === 0) {
                    this.setState({ 
                        requireFirstName: json.result.requireFirstName,
                        requireLastName: json.result.requireLastName,
                        requirePassword: json.result.requirePassword,

                        email: json.result.email,
                        firstName: json.result.firstName,
                        middleName: json.result.middleName,
                        lastName: json.result.lastName,
                    });
                }
                else if (json.result.status === 1) {
                    this.setState({ error: 'Your account could not be verified.<br></br>Cannot find user.' });
                }
                else if (json.result.status === 2) {
                    this.setState({ error: 'Your account could not be verified.<br></br>Cannot find member.' });
                }
                else if (json.result.status === 3) {
                    this.setState({ error: 'Your account could not be verified.<br></br>Cannot update member.' });
                }
                else if (json.result.status === 4) {
                    this.setState({ error: 'Your account could not be verified.<br></br>Token is invalid.' }); 
                }
                else if (json.result.status === 5) {
                    this.setState({ error: 'Your account could not be verified.<br></br>Token is expired.' });
                }
                else if (json.result.status === 6) {
                    this.setState({ error: 'Your account could not be verified.<br></br>Complete profile is not required.' });
                }
            }
            else {
                this.setState({ error: `Your account could not be verified.<br></br>${json.errors[0]}` });
            }
        })
    }

    // Event Handlers
    submitForm(e) {
        e.preventDefault();

        if (!this.state.isFormLoading) {
            this.exitToast();

            const isFormValid = formService.validate(this.form);

            let firstName = "";
            let middleName = "";
            let lastName = "";

            if (this.state.useMiddleName) {
                firstName = e.target.firstName.value;
                middleName = e.target.middleName.value;
                lastName = e.target.lastName.value;
            }
            else {
                firstName = e.target.fullName.value.split(" ")[0];
                lastName = e.target.fullName.value.split(" ")[1];
            }

            const password = this.state.requirePassword ? e.target.password.value : '';
            const confirm = this.state.requirePassword ? e.target.confirm.value : ''; 

            if (this.state.requirePassword && (password !== confirm)) {
                formService.showInputError(this.form, "confirm", "This password does not match");
                return;
            }

            if(!isFormValid) return;

            this.setState({ isFormLoading: true });

            let completeProfileRequest = {firstName, middleName, lastName, password};
            completeProfileRequest.token = this.props.match.params.token;

            recaptchaService.getToken("completeProfile")
            .then(token => {
                completeProfileRequest.recaptchaToken = token;

                memberService.completeProfile(completeProfileRequest)
                .then(json => {    
                    if (json.succeeded) {
                        memberService.goToMembers();
                    }
                    else {
                        this.setState({ isFormLoading: false, toastMessage: json.errors[0] });
                        formService.hideAllErrors(this.form);
                        gsap.to(this.toast, {
                            duration: 0.5,
                            visibility: 'visible',
                            opacity: 1,
                            y: -25,
                        })
                    }
                });
            });
        }
    }

    exitToast() {
        gsap.to(this.toast, {
            duration: 0.3,
            opacity: 0,
            y: 25,
            onComplete: () => {
                gsap.set(this.toast, {
                    visibility: 'hidden'
                });
            },
        })
    }

    useMiddleName() {
        this.setState({ useMiddleName: true });
    }

    // Component
    render() {
        const tokenValidated = memberService.completeProfileValidated;

        const requireFirstName = this.state.requireFirstName;
        const requireLastName = this.state.requireLastName;
        const requirePassword = this.state.requirePassword;

        const error = this.state.error;

        return (
            <div className={styles['form-container']} ref={ref => this.container = ref}>
                {
                    tokenValidated &&
                    <>
                        <h1 className={styles['title']}>Complete Profile</h1>
                        <form className={styles['form']} ref={ref => this.form = ref} onSubmit={this.submitForm}>
                            <Input className={styles['first-name-input']} type='text' name='firstName' placeholder='First Name' defaultValue={this.state.firstName} disabled={!requireFirstName} required />
                            <Input className={styles['middle-name-input']} type='text' name='middleName' placeholder='Middle Name' defaultValue={this.state.middleName} />
                            <Input className={styles['last-name-input']} type='text' name='lastName' placeholder='Last Name' defaultValue={this.state.lastName} disabled={!requireLastName} required />
                            <Input className={styles['email-input']} type='email' name='email' placeholder='E-mail' value={this.state.email} disabled={true} />
                            {
                                requirePassword &&
                                <>
                                    <Input className={styles['password-input']} type='password' name='password' placeholder='Password' required />
                                    <Input className={styles['confirm-input']} type='password' name='confirm' placeholder='Confirm Password' required />
                                </>
                            }
                            <PrimaryButton loading={this.state.isFormLoading} whiteSpinner={true} noAnimate={true} scale={1.02} className={styles['complete-button']} type='submit' formNoValidate>Create an account</PrimaryButton>
                        </form>
                    </>
                }
                {
                    !tokenValidated &&
                    <>
                        <h1 className={styles['error-title']}>Complete Profile Error</h1>
                        <p className={styles['description']} dangerouslySetInnerHTML={{ __html: error}}></p>
                        <Link to='/help-and-support'>
                            <PrimaryButton noAnimate={true} className={styles['help-button']}>Get Help</PrimaryButton>
                        </Link>
                    </>
                }
                <div className={styles['login-container']}>
                    <span className={styles['login-text']}>Already have an account?</span>
                    <Link to='/login'><span className={styles['login-button']}>Login</span></Link>
                </div>
                <Toast className={styles['toast']} error={true} title='Error' description={this.state.toastMessage} onExit={this.exitToast} ref={ref => this.toast = ref} />
            </div>
        )
    }
}

export default withRouter(CompleteProfile);