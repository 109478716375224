import React from 'react'
import { Link } from 'react-router-dom'
import gsap from 'gsap'

import bootstrapService from '../../services/BootstrapService'

import Navbar from '../shared/Navbar/Navbar'
import Contact from './components/Contact/Contact'
import Subscribe from '../shared/Subscribe/Subscribe'
import Footer from '../shared/Footer/Footer'

import emailImage from './images/email.svg'
import addressImage from './images/address.svg'
import faxImage from './images/fax.svg'
import phoneImage from './images/phone.svg'
import websiteImage from './images/website.svg'

import styles from './Help.module.css'

class Help extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.title = null;
        this.description = null;
        this.contacts = [];
        this.dividers = [];
    }

    // Lifecycle 
    componentDidMount() {
        window.scrollTo(0, 0);
        this.setupAnimations();
    }

    setupAnimations() {
        gsap.from([this.title, this.description, this.contacts], {
            duration: 0.5,
            stagger: 0.25,
            delay: 0.3,
            y: 25,
            opacity: 0,
        });

        gsap.from(this.dividers, {
            duration: 0.5,
            stagger: 0.25,
            delay: 0.9,
            y: 25,
            opacity: 0,
        });
    }

    // Component
    render() {
        const helpData = bootstrapService.data.landing.pages.find(p => p.name === "help-support");

        const header = helpData ? helpData.header : null;
        const body = helpData ? helpData.bodyText : null;

        const siteData = bootstrapService.data.site;

        const email = siteData.siteEmail;
        const phone = siteData.sitePhone;
        const address1 = siteData.siteAddress.street;
        const address2 = siteData.siteAddress.city.concat(", ", siteData.siteAddress.state, " ", siteData.siteAddress.zip);
        const fullAddress = address1.concat(" ", address2);
        const url = siteData.name;
        const fax = siteData.siteFax;

        // Reset Refs
        this.contacts = [];

        return (
            <>
                <Navbar white={true} />
                <div className={styles['background']}>
                    <section className={styles['content']}>
                        <h1 className={styles['title']} ref={ref => this.title = ref}>{header}</h1>
                        <p className={styles['description']} ref={ref => this.description = ref}>{body}</p>
                        <div className={styles['items']}>
                            <Link to={`mailto:${email}`} target="_blank" rel="noreferrer">
                                <Contact image={emailImage} title='Email:' subtitle={email} ref={ref => this.contacts.push(ref)} />
                            </Link>
                            <div className={styles['divider']} ref={ref => this.dividers.push(ref)}></div>
                            <Link to={`http://maps.google.com/?q=${fullAddress}`} target="_blank" rel="noreferrer">
                                <Contact image={addressImage} title='Mailing Address:' subtitle={fullAddress} ref={ref => this.contacts.push(ref)} />
                            </Link>
                            <div className={styles['divider']} ref={ref => this.dividers.push(ref)}></div>
                            <Link to={`fax:${fax}`}>
                                <Contact image={faxImage} title='Fax:' subtitle={fax} ref={ref => this.contacts.push(ref)} />
                            </Link>
                            <div className={styles['divider']} ref={ref => this.dividers.push(ref)}></div>
                            <Link to={`tel:${phone}`}>
                                <Contact image={phoneImage} title='Phone:' subtitle={phone} ref={ref => this.contacts.push(ref)} />
                            </Link>
                            <div className={styles['divider']} ref={ref => this.dividers.push(ref)}></div>
                            <a href={`https://${url}`} target="_blank" rel="noopener noreferrer">
                                <Contact image={websiteImage} title='Website:' subtitle={url} ref={ref => this.contacts.push(ref)} />
                            </a>
                        </div>
                    </section>
                    <Subscribe />
                    <Footer />
                </div>
            </>
        )
    }
}

export default Help;