import React from 'react'

import image from './images/error.png'

import styles from './NoCoursesCard.module.css'

class NoCoursesCard extends React.Component {
    render() {
        return (
            <div className={`${styles['content']} ${this.props.className}`} ref={this.props.innerRef}>
                <div className={styles['image-container']}>
                    <img src={image} alt='' className={styles['image']} />
                </div>
                <div className={styles['text-container']}>
                    <p className={styles['text']}>Sorry, we couldn’t find any results to match your search.</p>
                </div>
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <NoCoursesCard 
  innerRef={ref} {...props}
/>);