import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import gsap from 'gsap'

import bootstrapService from '../../services/BootstrapService'
import memberService from '../../services/MemberService'

import PrimaryButton from '../shared/Buttons/PrimaryButton/PrimaryButton'

import styles from './ConfirmEmail.module.css'

class ConfirmEmail extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.container = null;
        this.form = null;

        // State
        this.state = {
            isFormLoading: false,
            error: 'Your email address could not be verified.',
        }
    }

    // Lifecycle
    componentDidMount() {
        this.setupAnimations();
        this.setBootstrapCallback();
    }

    // Helpers
    setupAnimations() {
        gsap.from(this.container, {
            duration: 0.5,
            opacity: 0,
        })
    }

    setBootstrapCallback() {
        bootstrapService.setCallback(() => {
            return this.validateToken();
        })
    }

    validateToken() {
        const token = this.props.match.params.token;

        return memberService.validateConfirmEmailToken(token)
        .then(json => {
            if (json.succeeded) {
                if (json.result.status === 0) {
                    this.forceUpdate();
                }
                else if (json.result.status === 1) {
                    this.setState({ error: 'Your email address could not be verified.<br></br>Token is invalid.' });
                }
                else if (json.result.status === 2) {
                    this.setState({ error: 'Your email address could not be verified.<br></br>Token is expired.' });
                }
                else if (json.result.status === 3) {
                    this.setState({ error: 'Your email address could not be verified.<br></br>Token is expired. A new email has been sent.' });
                }
                else if (json.result.status === 4) {
                    this.setState({ error: 'Your email address could not be verified.<br></br>Confirm email is not required.' });
                }
            }
            else {
                this.setState({ error: `Your email address could not be verified.<br></br>${json.errors[0]}` });
            }
        })
    }

    // Component
    render() {
        const tokenValidated = memberService.confirmEmailValidated;

        const title = tokenValidated ? 'Email Verified' : 'Confirm Email Error';
        const description = tokenValidated ? ' Thank you for verifying your email address.<br></br>Please login using the button below.' : this.state.error;
        const button = tokenValidated ? 'Login' : 'Get Help';
        
        return (
            <div className={styles['form-container']} ref={ref => this.container = ref}>
                <h1 className={styles['title']}>{title}</h1>
                <p className={styles['description']} dangerouslySetInnerHTML={{ __html: description}}></p>
                <Link to={tokenValidated ? '/login' : '/help-and-support'}>
                    <PrimaryButton className={styles['button']} loading={this.state.isFormLoading} whiteSpinner={true} noAnimate={true} scale={1.02}>{button}</PrimaryButton>
                </Link>
                <div className={styles['register-container']}>
                    <span className={styles['register-text']}>Don’t have an account?</span>
                    <Link to='/register'><span className={styles['register-button']}>Sign up</span></Link>
                </div>
            </div>
        )
    }
}

export default withRouter(ConfirmEmail);