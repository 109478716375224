import React from 'react'
import gsap from 'gsap'

import styles from './WhyUsCard.module.css'

class WhyUsCard extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.image = null;

        // This Binding
        this.animateCardEnter = this.animateCardEnter.bind(this);
        this.animateCardLeave = this.animateCardLeave.bind(this);
    }

    // Event Handlers
    animateCardEnter() {
        if (window.innerWidth >= 768) {
            gsap.to(this.image, {
                duration: 0.5,
                scale: 1.05,
            });
        }
    }

    animateCardLeave() {
        if (window.innerWidth >= 768) {
            gsap.to(this.image, {
                duration: 0.5,
                scale: 1,
            });
        }
    }

    // Component
    render() {
        const { header, body } = this.props.data;

        return (
            <div className={`${styles['content']} ${this.props.className} ${this.props.inverted ? styles['inverted'] : ''}`} ref={this.props.innerRef} onMouseEnter={this.animateCardEnter} onMouseLeave={this.animateCardLeave}>
                <div className={styles['info']}>
                    <h3 className={styles['title']}>{header}</h3>
                    <p className={styles['description']}>{body}</p>
                </div>
                <img src={this.props.image} alt='' className={styles['image']} ref={ref => this.image = ref} />
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <WhyUsCard 
  innerRef={ref} {...props}
/>);