import React from 'react'
import { Link } from 'react-router-dom'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import bootstrapService from '../../services/BootstrapService'

import Navbar from '../shared/Navbar/Navbar'
import Question from './components/Question/Question'
import PrimaryButton from '../shared/Buttons/PrimaryButton/PrimaryButton'
import Subscribe from '../shared/Subscribe/Subscribe'
import Footer from '../shared/Footer/Footer'
import image from './images/FAQ.png'

import styles from './FAQ.module.css'


class FAQ extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.title = null;
        this.questions = [];
        this.dividers = [];
        this.askTitle = null;
        this.askButton = null;
        this.askImage = null;

        // State
        this.state = {
            openQuestionIndex: null,
        }

        // GSAP
        gsap.registerPlugin(ScrollTrigger);

        // This Binding
        this.didOpenQuestion = this.didOpenQuestion.bind(this);
        this.didCloseQuestion = this.didCloseQuestion.bind(this);
    }

    // Lifecycle 
    componentDidMount() {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 0);

        window.scrollTo(0, 0);
        this.setupAnimations();
    }

    // Helpers
    setupAnimations() {
        gsap.from([this.title, this.questions.slice(0, 3)], {
            duration: 0.5,
            delay: 0.3,
            stagger: 0.25,
            y: 25,
            opacity: 0,
        });

        gsap.from(this.dividers.slice(0, 3), {
            duration: 0.5,
            stagger: 0.25,
            delay: 0.7,
            y: 25,
            opacity: 0,
        });

        for (const question of this.questions.slice(3, this.questions.length)) {
            gsap.from(question, {
                scrollTrigger: {
                    trigger: question,
                    start: 'top 90%',
                },
                duration: 0.5,
                y: 25,
                opacity: 0,
            });
        }

        for (const divider of this.dividers.slice(3, this.dividers.length)) {
            gsap.from(divider, {
                scrollTrigger: {
                    trigger: divider,
                    start: 'top 90%',
                },
                duration: 0.5,
                y: 25,
                opacity: 0,
            });
        }

        gsap.from(this.askTitle, {
            scrollTrigger: {
                trigger: this.askTitle,
                start: 'top 90%',
            },
            duration: 0.5,
            y: 25,
            opacity: 0,
        });

        gsap.from(this.askImage, {
            scrollTrigger: {
                trigger: this.askImage,
                start: 'top 90%',
            },
            duration: 0.5,
            y: 25,
            opacity: 0,
        });

    }

    didOpenQuestion(index) {
        this.setState({ openQuestionIndex: index })
        ScrollTrigger.refresh();
    }

    didCloseQuestion(index) {
        this.setState({ openQuestionIndex: null })
        ScrollTrigger.refresh();
    }

    refreshScrollTrigger() {
        ScrollTrigger.refresh();
    }

    // Component
    render() {
        const data = bootstrapService.data.landing.pages.find(p => p.name === "faq");

        const header = data ? data.header : null;
        const faqs = data ? data.faqs : null;

        return (
            <>
                <Navbar white={true} />
                <div className={styles['background']}>
                    <section className={styles['content']}>
                        <h1 className={styles['title']} ref={ref => this.title = ref}>{header}</h1>
                        <div className={styles['questions']}>
                            {
                                faqs.map((faq, faqIndex) => {
                                    return  <React.Fragment key={faqIndex}>
                                                <Question faq={faq} index={faqIndex} isOpen={this.state.openQuestionIndex === faqIndex} didOpen={this.didOpenQuestion} didClose={this.didCloseQuestion} refreshScrollTrigger={this.refreshScrollTrigger} ref={ref => this.questions[faqIndex] = ref} />
                                                {
                                                    faqIndex < faqs.length - 1 &&
                                                    <div className={styles['divider']} ref={ref => this.dividers[faqIndex] = ref}></div>
                                                }
                                            </React.Fragment>
                                })
                            }
                        </div>
                        <div className={styles['ask-container']}>
                            <h2 className={styles['ask-title']} ref={ref => this.askTitle = ref}>Did not find the answer to your question?</h2>
                            <Link to='/ask-question'><PrimaryButton zoom={true} scrollTrigger={true} duration={0.5}  className={styles['ask-button']}>Ask a question</PrimaryButton></Link>
                            <div className={styles['image-container']}>
                                <img src={image} alt='' className={styles['image']} ref={ref => this.askImage = ref} />
                            </div>
                        </div>
                    </section>
                    <Subscribe />
                    <Footer />
                </div>
            </>
        )
    }
}

export default FAQ;