import React from 'react'

import bundle from './images/bundle.svg'
import check from './images/check.svg'
import PrimaryButton from '../../../shared/Buttons/PrimaryButton/PrimaryButton'

import styles from './BuyBundleCard.module.css'

class BuyBundleCard extends React.Component {
    // Component
    render() {
        const title = this.props.data.item.title;
        const description = this.props.data.item.descriptionText;
        const courses = this.props.data.courseDigests;
        const price = this.props.data.item.price;

        return (
            <div className={styles['content']} ref={this.props.innerRef}>
                <div className={styles['circle']}>
                    <img src={bundle} alt='' className={styles['image']} />
                </div>
                <div className={styles['info']}>
                    <div className={styles['info-container']}>
                        <h3 className={styles['title']}>{title}</h3>
                        <p className={styles['description']}>{description}</p>
                        <div className={`${styles['courses']} ${courses.length >= 10 ? styles['courses-grid'] : ''}`}>
                            {
                                courses.map((course, courseIndex) => {
                                    return  <div className={styles['course']} key={courseIndex}>
                                                <img src={check} alt='' className={styles['course-check']} />
                                                <span className={styles['course-title']}>{course.title}</span>
                                            </div>
                                })
                            }
                        </div>
                    </div>
                    <div className={styles['buy-container']}>
                        <div className={styles['price']}>
                            <span className={styles['dollars']}>${String(price).split('.')[0]}</span>
                            <span className={styles['cents']}>.{String(price).split('.')[1] ? String(price).split('.')[1] : '00'}</span>
                        </div>
                        <PrimaryButton className={styles['button']} noAnimate={true} onClick={this.props.onBuy}>Buy Now</PrimaryButton>
                    </div>
                </div>
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <BuyBundleCard 
  innerRef={ref} {...props}
/>);