import React from 'react'

// import Component from './components/Component/Component'

import styles from './CourseObjective.module.css'

class CourseObjective extends React.Component {
    render() {
        return (
            <div className={styles['content']} ref={this.props.innerRef}>
                <div className={styles['circle']}>
                    <span className={styles['number']}></span>
                </div>
                <p className={styles['objective']}>{this.props.data}</p>
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <CourseObjective 
  innerRef={ref} {...props}
/>);