import React from 'react'

// import Component from './components/Component/Component'

import styles from './Contact.module.css'

class Contact extends React.Component {
    render() {
        const { image, title, subtitle } = this.props;
        return (
            <div className={styles['content']} ref={this.props.innerRef}>
                <div className={styles['circle']}>
                    <img src={image} alt='' />
                </div>
                <div className={styles['info']}>
                    <span className={styles['title']}>{title}</span>
                    <span className={styles['subtitle']}>{subtitle}</span>
                </div>
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <Contact 
  innerRef={ref} {...props}
/>);