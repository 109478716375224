import React from 'react'
import { Link } from 'react-router-dom'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from '../../gsap/SplitText'

import bootstrapService from '../../services/BootstrapService'

import Navbar from '../shared/Navbar/Navbar'
import Subscribe from '../shared/Subscribe/Subscribe'
import Footer from '../shared/Footer/Footer'
import AuthorityDropdown from './components/AuthorityDropdown/AuthorityDropdown'
import RequirementCard from './components/RequirementCard/RequirementCard'
import Footnote from './components/Footnote/Footnote'
import Contact from './components/Contact/Contact'

import orgImage from './images/org.svg'
import phoneImage from './images/phone.svg'
import emailImage from './images/email.svg'
import websiteImage from './images/website.svg'

import styles from './Requirements.module.css'

class Requirements extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.title = null;
        this.divider = null;
        this.dropdown = null;
        this.authority = null;
        this.requirements = [];
        this.footnotes = [];
        this.contactTitle = null;
        this.contacts = [];
        this.contactDividers = [];

        // State
        this.state = {
            currentAuthority: this.props.match.params.authority,
            isAuthorityDropdownOpen: false,
        }

        // GSAP
        gsap.registerPlugin(ScrollTrigger);
        gsap.registerPlugin(SplitText);

        // This Binding
        this.changeAuthority = this.changeAuthority.bind(this);
        this.toggleAuthorityDropdown = this.toggleAuthorityDropdown.bind(this);
        this.closeDropdowns = this.closeDropdowns.bind(this);
    }

    // Lifecyle
    componentDidMount() {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 0);

        window.scrollTo(0, 0);
        this.setupAnimations();
    }

    // Helpers
    setupAnimations() {
        let title = new SplitText(this.title, {type: 'lines'});
        let elements = title.lines;
        let requirementsDelay = 0.75;
        if (window.innerWidth >= 768) {
            elements.push(this.divider);
            requirementsDelay = 0.9
        }
        if (bootstrapService.data.landing.pages.find(p => p.name === "requirements").requirements.length > 1) {
            elements.push(this.dropdown);
            requirementsDelay = 1.15
        }
        
        gsap.from(elements, {
            duration: 0.5,
            stagger: 0.25,
            delay: 0.3,
            y: 25,
            opacity: 0,
        });

        gsap.from([this.authority, this.requirements[0]], {
            duration: 0.5,
            stagger: 0.25,
            delay: requirementsDelay,
            y: 25,
            opacity: 0,
        });

        for (const requirement of this.requirements.slice(1, this.requirements.length)) {
            gsap.from(requirement, {
                scrollTrigger: {
                    trigger: requirement,
                    start: 'top 90%',
                },
                duration: 0.5,
                y: 25,
                opacity: 0,
            });
        }

        for (const footnote of this.footnotes) {
            gsap.from(footnote, {
                scrollTrigger: {
                    trigger: footnote,
                    start: 'top 90%',
                },
                duration: 0.5,
                y: 25,
                opacity: 0,
            });
        }

        let contactTitle = new SplitText(this.contactTitle, {type: 'lines'});
        gsap.from(contactTitle.lines, {
            scrollTrigger: {
                trigger: this.contactTitle,
                start: 'top 90%',
            },
            duration: 0.5,
            stagger: 0.15,
            y: 25,
            opacity: 0,
        });

        for (const contact of this.contacts) {
            gsap.from(contact, {
                scrollTrigger: {
                    trigger: contact,
                    start: 'top 90%',
                },
                duration: 0.5,
                y: 25,
                opacity: 0,
            });
        }

        for (const divider of this.contactDividers) {
            gsap.from(divider, {
                scrollTrigger: {
                    trigger: divider,
                    start: 'top 90%',
                },
                duration: 0.5,
                y: 25,
                opacity: 0,
            });
        }
    }

    renderRequirementsAnimations() {
        gsap.from([this.authority, ...this.requirements, ...this.footnotes], {
            duration: 0.5,
            stagger: 0.25,
            y: 25,
            opacity: 0,
        });
    }

    // Event Handlers
    changeAuthority(authority) {
        this.setState({ currentAuthority: authority.toLowerCase() }, () => {
            this.props.history.push(`/requirements/${authority.toLowerCase()}`);
            window.scrollTo(0, 0);
            this.renderRequirementsAnimations();
        })
    }

    toggleAuthorityDropdown() {
        this.setState({ isAuthorityDropdownOpen: !this.state.isAuthorityDropdownOpen })
    }

    closeDropdowns(e) {
        e.stopPropagation();
        this.setState({ isAuthorityDropdownOpen: false });
    }

    // Component
    render() {
        const data = bootstrapService.data.landing.pages.find(p => p.name === "requirements");

        const header = data ? data.header : null;
        const allRequirements = data ? data.requirements : null;
        const currentRequirement = allRequirements ? allRequirements.find(r => r.authorityName.toLowerCase() === this.state.currentAuthority) : null;

        return (
            <>
                <Navbar active='requirements' />
                <div className={styles['background']}>
                    <section className={styles['content']} onClick={this.closeDropdowns}>
                        <h1 className={styles['title']} ref={ref => this.title = ref}>{header}</h1>
                        <div className={`${styles['divider']} ${styles['top-divider']}`} ref={ref => this.divider = ref}></div>
                        {
                            allRequirements.length > 1 &&
                            <AuthorityDropdown className={styles['dropdown']} currentAuthority={currentRequirement.authorityName} requirements={allRequirements} onToggle={this.toggleAuthorityDropdown} isOpen={this.state.isAuthorityDropdownOpen} changeAuthority={this.changeAuthority} ref={ref => this.dropdown = ref} />
                        }
                       
                        <h2 className={styles['authority']} ref={ref => this.authority = ref}>{currentRequirement.authorityName}</h2>
                        <div className={styles['requirements']}>
                        {
                            currentRequirement.requirementValues && currentRequirement.requirementValues.map((requirement, requirementIndex) => {
                                return  <RequirementCard data={requirement} key={requirementIndex} ref={ref => this.requirements[requirementIndex] = ref}/>
                            })
                        }
                        </div>
                        <div className={styles['footnotes']}>
                        {
                            currentRequirement.requirementFootnotes && currentRequirement.requirementFootnotes.map((footnote, footnoteIndex) => {
                                return  <Footnote data={footnote} key={footnoteIndex} ref={ref => this.footnotes[footnoteIndex] = ref} />
                            })
                        }
                        </div>
                        <h2 className={styles['organization']} ref={ref => this.contactTitle = ref}>{currentRequirement.organizationName}</h2>
                        <div className={styles['contacts']}>
                            <Contact image={orgImage} title='Organization:' subtitle={currentRequirement.organizationName} ref={ref => this.contacts[0] = ref} />
                            <div className={styles['divider']} ref={ref => this.contactDividers[0] = ref}></div>
                            <Link to={`tel:${currentRequirement.organizationPhone}`}>
                                <Contact image={phoneImage} title='Phone:' subtitle={currentRequirement.organizationPhone} ref={ref => this.contacts[1] = ref} />
                            </Link>
                            <div className={styles['divider']} ref={ref => this.contactDividers[1] = ref}></div>
                            <Link to={`mailto:${currentRequirement.organizationEmail}`}>
                                <Contact image={emailImage} title='Email:' subtitle={currentRequirement.organizationEmail} ref={ref => this.contacts[2] = ref} />
                            </Link>
                            <div className={styles['divider']} ref={ref => this.contactDividers[2] = ref}></div>
                            <a href={currentRequirement.organizationUrl} target="_blank" rel="noreferrer">
                                <Contact image={websiteImage} title='Website:' subtitle={currentRequirement.organizationUrl} ref={ref => this.contacts[3] = ref} />
                            </a>
                        </div>
                    </section>
                    <Subscribe />
                    <Footer />
                </div>
            </>
        )
    }
}

export default Requirements;