import React from 'react'

import scheduleService from '../../../services/ScheduleService'

import PrimaryButton from '../Buttons/PrimaryButton/PrimaryButton'
import previousImage from './images/less-than.svg'
import nextImage from './images/greater-than.svg'
import clockImage from './images/clock.svg'

import styles from './Calendar.module.css'

class Calendar extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // State
        this.state = {
            currentSchedule: this.props.schedules[0],
            currentMonth: scheduleService.getMonth(this.props.schedules[0].startDate),
            currentYear: scheduleService.getYear(this.props.schedules[0].startDate),
            days: [],
        }

        // This Binding
        this.clickPreviousMonth = this.clickPreviousMonth.bind(this);
        this.clickNextMonth = this.clickNextMonth.bind(this);
        this.clickDay = this.clickDay.bind(this);
    }

    // Lifecyle
    componentDidMount() {
        this.setupSchedules();
    }

    // Helpers
    setupSchedules() {
        const days = this.getDays(scheduleService.getMonth(this.state.currentSchedule.startDate), scheduleService.getYear(this.state.currentSchedule.startDate), this.state.currentSchedule);
        this.setState({ days: days }, this.props.selectSchedule(this.state.currentSchedule));
    }

    getDays(month, year, currentSchedule) {
        const firstDayOfWeek = scheduleService.getFirstDayOfWeek(month, year);
        const lastDayOfWeek = scheduleService.getLastDayOfWeek(month, year);
        const daysInMonth = scheduleService.getDaysInMonth(month, year);
        const daysinPreviousMonth = scheduleService.getDaysInPreviousMonth(month, year);

        let days = [];

        // Add Previous Month's Days
        for (let i = daysinPreviousMonth - firstDayOfWeek + 1; i <= daysinPreviousMonth; i++) {
            days.push({ "day": i, "disabled": true, "unselected": false, "selected": false })
        }

        // Add This Month's Days
        for (let i = 1; i <= daysInMonth; i++) {

            let unselected = false;
            let selected = false;

            this.props.schedules.filter(s => scheduleService.getMonth(s.startDate) === month && scheduleService.getYear(s.startDate) === year).forEach((schedule, index) => {
                const day = scheduleService.getDay(schedule.startDate);
                if (day === i) {
                    unselected = true;

                    if (schedule.startDate === currentSchedule.startDate) {
                        selected = true;
                    }
                }
            })

            days.push({ "day": i, "disabled": false, "unselected": unselected, "selected": selected })
        }

        // Add Next Month's Days
        for (let i = 1; i <= 6-lastDayOfWeek; i++) {
            days.push({ "day": i, "disabled": true, "unselected": false, "selected": false });
        }

        return days;
    }

    // Event Handlers
    clickPreviousMonth(e) {
        e.preventDefault();

        let previousMonth = this.state.currentMonth - 1;
        let year = this.state.currentYear;
        if (previousMonth === -1) {
            previousMonth = 11;
            year = year - 1;
        }

        const days = this.getDays(previousMonth, year, this.state.currentSchedule);
        this.setState({ currentMonth: previousMonth, currentYear: year, days: days });
    }

    clickNextMonth(e) {
        e.preventDefault();

        let nextMonth = this.state.currentMonth + 1;
        let year = this.state.currentYear;
        if (nextMonth === 12) {
            nextMonth = 0;
            year = year + 1;
        }

        const days = this.getDays(nextMonth, year, this.state.currentSchedule);
        this.setState({ currentMonth: nextMonth, currentYear: year, days: days });
    }

    clickDay(day) {
        this.props.schedules.forEach((schedule, index) => {
            if (scheduleService.getDay(schedule.startDate) === day.day && scheduleService.getMonth(schedule.startDate) === this.state.currentMonth && scheduleService.getYear(schedule.startDate) === this.state.currentYear) {
                const days = this.getDays(this.state.currentMonth, this.state.currentYear, schedule);
                this.setState({ days: days, currentSchedule: schedule }, this.props.selectSchedule(schedule));
            }
        })
    }

    // Component
    render() {
        const daysOfWeek = ["SUN","MON","TUE","WED","THU","FRI","SAT"];
        const currentMonthAndYear = new Date(this.state.currentYear, this.state.currentMonth).toLocaleDateString("en-US", {month: 'long', year: 'numeric'});;
        const days = this.state.days;

        const selectedDate = new Date(this.state.currentSchedule.startDate).toLocaleDateString("en-US", {weekday: 'long', month: 'long', day: 'numeric'});
        const selectedTime = `${new Date(this.state.currentSchedule.startDate).toLocaleTimeString("en-US", {hour: "2-digit", minute: "2-digit"})} - ${new Date(this.state.currentSchedule.endDate).toLocaleTimeString("en-US", {hour: "2-digit", minute: "2-digit"})}`;
        const selectedDuration = `${Math.abs(new Date(this.state.currentSchedule.endDate) - new Date(this.state.currentSchedule.startDate)) / 36e5} hours`;

        return (
            <div className={styles['content']} ref={this.props.innerRef}>
                <div className={styles['calendar']}>
                    <div className={styles['top']}>
                        <h3 className={styles['title']}>{currentMonthAndYear}</h3>
                        <div className={styles['controls']}>
                            <div className={`${styles['control']} ${styles['previous-control']}`} onClick={this.clickPreviousMonth}>
                                <img src={previousImage} alt='' className={styles['previous']} />
                            </div>
                            <div className={`${styles['control']} ${styles['next-control']}`} onClick={this.clickNextMonth}>
                                <img src={nextImage} alt='' className={styles['previous']} />
                            </div>
                        </div>
                    </div>
                    <div className={styles['days']}>
                        <div className={`${styles['days-row']} ${styles['first-row']}`}>
                        {
                            daysOfWeek.map((day, index) => {
                                return <div key={index} className={styles['day-of-week']}>
                                    <div className={styles['day-of-week-title']}>{day}</div>
                                </div>
                            })
                        }
                        </div>
                        {
                            [...Array(6)].map((row, rowIndex) => {
                                return <div key={rowIndex} className={styles['days-row']}>
                                {
                                    days.slice(rowIndex * 7, (rowIndex+1)*7).map((day, dayIndex) => {
                                        const canClick = day.unselected || day.selected;
                                        return <div key={dayIndex} className={`${styles['day']} ${day.disabled ? styles['day-disabled'] : ''} ${day.unselected ? styles['day-unselected'] : ''} ${day.selected ? styles['day-selected'] : ''}`} onClick={canClick ? () => this.clickDay(day) : undefined}>
                                            <div className={styles['day-title']}>{day.day}</div>
                                        </div>
                                    })
                                }
                                </div>
                            })
                        }
                        
                    </div>
                </div>
                <div className={styles['info']}>
                    <div className={styles['info-item']}>
                        <div className={styles['info-title']}>Date</div>
                        <div className={styles['info-value']}>{selectedDate}</div>
                    </div>
                    <div className={styles['info-item']}>
                        <div className={styles['info-title']}>Time</div>
                        <div className={styles['info-value']}>{selectedTime} ({scheduleService.getTimeZone()})</div>
                    </div>
                    <div className={styles['info-item']}>
                        <div className={styles['info-title']}>Duration</div>
                        <div className={`${styles['info-value']} ${styles['info-duration']}`}>
                            <img src={clockImage} alt='' className={styles['duration']} />
                            {selectedDuration}
                        </div>
                    </div>
                    <PrimaryButton className={styles['button']} noAnimate={true} onClick={this.props.onBuy}>Buy Now</PrimaryButton>
                </div>
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <Calendar 
  innerRef={ref} {...props}
/>);