import React from 'react'
import gsap from 'gsap'

import error from './images/error.svg'
import check from './images/check.svg'
import exit from './images/exit.svg'

import styles from './Toast.module.css'

class Toast extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.button = null

        // This Binding
        this.animateButtonEnter = this.animateButtonEnter.bind(this);
        this.animateButtonLeave = this.animateButtonLeave.bind(this);
    }

    // Event Handlers
    animateButtonEnter() {
        gsap.to(this.button, {
            duration: 0.3,
            scale: 1.04,
        })
    }

    animateButtonLeave() {
        gsap.to(this.button, {
            duration: 0.3,
            scale: 1,
        })
    }

    // Component
    render() {
        return (
            <div className={`${styles['content']} ${this.props.error ? styles['error'] : ''} ${this.props.className}`} ref={this.props.innerRef}>
                <div className={styles['icon']}><img src={this.props.error ? error : check} alt='' /></div>
                <div className={styles['text']}>
                    <h3 className={styles['title']}>{this.props.title}</h3>
                    <p className={styles['description']}>{this.props.description}</p>
                </div>
                <div className={styles['exit']} onClick={this.props.onExit} onMouseEnter={this.animateButtonEnter} onMouseLeave={this.animateButtonLeave} ref={ref => this.button = ref}><img src={exit} alt='' /></div>
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <Toast 
  innerRef={ref} {...props}
/>);