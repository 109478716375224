import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import './App.css'

import Spinner from './components/shared/Spinner/Spinner'
import Home from './components/Home/Home'
import Approvals from './components/Approvals/Approvals'
import Courses from './components/Courses/Courses'
import CourseDetails from './components/CourseDetails/CourseDetails'
import Pricing from './components/Pricing/Pricing'
import Requirements from './components/Requirements/Requirements'
import FAQ from './components/FAQ/FAQ'
import Help from './components/Help/Help'
import AffiliatedSites from './components/AffiliatedSites/AffiliatedSites'
import Terms from './components/Terms/Terms'
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy'
import RefundPolicy from './components/RefundPolicy/RefundPolicy'
import ComplaintPolicy from './components/ComplaintPolicy/ComplaintPolicy'
import Modal from './components/shared/Modal/Modal'

import Error from './components/Error/Error'
import AppError from './components/shared/AppError/AppError'

import bootstrapService from './services/BootstrapService'
import imagePreloadService from './services/ImagePreloadService'

class App extends React.Component {
  constructor(props) {
    super(props);

    // State
    this.state = {
      dataLoading: true,
      dataError: false,
    }
  }

  // Lifecycle
  componentDidMount() {
    this.getBoostrap();
  }

  // Data
  getBoostrap() {
    bootstrapService.getBootstrap()
    .then(() => {
      this.setState({ dataLoading: false });
      imagePreloadService.loadImages();
    })
    .catch((e) => {
      console.log(`Get Bootstrap Error - ${e}`);
      this.setState({ dataError: true });
    })
  }

  // Component
  render() {
    const loading = this.state.dataLoading;
    const error = this.state.dataError

    return (
      <>
        {
          loading && !error &&
          <div className="spinner-container">
              <Spinner />
          </div>
        }

        {
          !loading && !error &&
          <Router>
            <Switch>
              {/* Routes */}
              <Route exact path="/" component={Home} />
              <Route exact path="/approvals" component={Approvals} />
              <Route exact path="/courses" component={Courses} />
              <Route exact path="/course/:id" component={CourseDetails} />
              <Route exact path='/pricing/:plan' component={Pricing} />
              <Route exact path="/requirements/:authority" component={Requirements} />
              <Route exact path="/faq" component={FAQ} />
              <Route exact path="/ask-question" component={Modal} />
              <Route exact path="/help-and-support" component={Help} />
              <Route exact path="/affiliated-sites" component={AffiliatedSites} />
              <Route exact path="/terms-and-conditions" component={Terms} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route exact path="/refund-policy" component={RefundPolicy} />
              <Route exact path="/complaint-resolution-policy" component={ComplaintPolicy} />
              <Route exact path="/login" component={Modal} />
              <Route exact path="/register" component={Modal} />
              <Route exact path="/forgot-password" component={Modal} />
              <Route exact path="/reset-password/:token" component={Modal} />
              <Route exact path="/confirm-email/:token" component={Modal} />
              <Route exact path="/complete-profile/:token" component={Modal} />
              <Route exact path="/buy-login" component={Modal} />
              <Route exact path="/buy-register" component={Modal} />
              <Route exact path="/payment" component={Modal} />

              {/* Redirects */}
              <Route exact path="/pricing"><Redirect to={`/pricing/${bootstrapService.defaultPlanType}`}></Redirect></Route>

              {/* Not Found */}
              <Route path="*" component={Error} />
              
            </Switch>
          </Router>
        }

        {
          error &&
          <div className="error-container">
            <AppError />
          </div>
        }
      </>
    )
  }
}

export default App;

