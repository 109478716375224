import React from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import bootstrapService from '../../services/BootstrapService'

import Navbar from '../shared/Navbar/Navbar'
import SiteCard from './components/SiteCard/SiteCard'
import Subscribe from '../shared/Subscribe/Subscribe'
import Footer from '../shared/Footer/Footer'

import styles from './AffiliatedSites.module.css'

class AffiliatedSites extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.title = null;
        this.description = null;
        this.cards = [];

        // GSAP
        gsap.registerPlugin(ScrollTrigger);
    }

    // Lifecycle 
    componentDidMount() {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 0);

        window.scrollTo(0, 0);
        this.setupAnimations();
    }

    setupAnimations() {
        gsap.from([this.title, this.description], {
            duration: 0.5,
            delay: 0.3,
            stagger: 0.25,
            y: 25,
            opacity: 0,
        });

        for (const [index, card] of this.cards.entries()) {
            gsap.from(card, {
                scrollTrigger: {
                    trigger: this.cards[0],
                    start: 'top 95%',
                },
                duration: 0.5,
                delay: index * 0.15 + 0.35,
                y: 25,
                opacity: 0,
            });
        }
    }

    // Component
    render() {
        const data = bootstrapService.data.landing.pages.find(p => p.name === "our-sites");

        const header = data ? data.header : null;
        const body = data ? data.bodyText : null;
        const sites = data ? data.sites : null;

        return (
            <>
                <Navbar />
                <div className={styles['background']}>
                    <section className={styles['content']}>
                        <h1 className={styles['title']} ref={ref => this.title = ref}>{header}</h1>
                        <p className={styles['description']} ref={ref => this.description = ref}>{body}</p>
                        <div className={styles['items']}>
                            {
                                sites.map((site, siteIndex) => {
                                    return  <SiteCard data={site} key={siteIndex} ref={ref => this.cards[siteIndex] = ref} />
                                })
                            }
                        </div>
                    </section>
                    <Subscribe />
                    <Footer />
                </div>
            </>
        )
    }
}

export default AffiliatedSites;