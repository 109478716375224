import React from 'react'

import error from './images/error.svg'

import styles from './Input.module.css'

class Input extends React.Component {
    constructor(props) {
        super(props);

        // Refs
        this.input = null;

        // This Binding
        this.clickInput = this.clickInput.bind(this);
    }

    // Event Handlers
    clickInput() {
        this.input.focus();
    }

    // Component
    render() {
        if (this.props.focusInput && this.input) {
            this.input.focus();
        }

        return (
            <div ref={this.props.innerRef} className={`${styles['content']} ${this.props.className} a4-input`} onClick={this.clickInput}>
                <input ref={ref => this.input = ref} className={styles['input']} type={this.props.type} name={this.props.name} placeholder={this.props.placeholder} defaultValue={this.props.defaultValue} required={this.props.required} onChange={this.props.onChange} />
                <span className={`${styles['error']} a4-input-error`}>This is an error</span>
                <img src={error} alt='' className={styles['image']} />
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <Input 
  innerRef={ref} {...props}
/>);