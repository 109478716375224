import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import bootstrapService from '../../../services/BootstrapService'

import AppStoreButton from '../Buttons/AppStoreButton/AppStoreButton'

import styles from './Footer.module.css'

class Footer extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // This Binding
        this.clickLogo = this.clickLogo.bind(this);
    }

    // Event Handlers
    clickLogo() {
        this.props.history.push('/');
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }

    // Component
    render() {
        const siteData = bootstrapService.data.site;
        const navs = bootstrapService.data.landing.footers;

        const logo = bootstrapService.data.site.logoUrl;

        const defaultPassType = bootstrapService.defaultPlanType;
        const defaultStateName = bootstrapService.defaultStateName;

        const siteName = siteData.name;
        const phone = siteData.sitePhone;
        const fax = siteData.siteFax;
        const email = siteData.siteEmail;
        const address = siteData.siteAddress.street.concat(" ", siteData.siteAddress.city, ", ", siteData.siteAddress.state, " ", siteData.siteAddress.zip);

        const homeData = bootstrapService.data.landing;
        const appStoreData = homeData.sections.find(s => s.name === "appStore");
        const appleUrl = appStoreData ? appStoreData.appleUrl : null;
        const googleUrl = appStoreData ? appStoreData.googleUrl : null;

        const ceTerm = bootstrapService.getCETerm();

        const showAddress = bootstrapService.getShowAddress();

        const year = new Date().getFullYear()
        
        return (
            <section className={styles['content']}>
                <div className={styles['lists']}>
                    <div className={styles['list']}>
                        <h4 className={styles['title']}>Company</h4>
                        <ul>
                            {
                                navs.find(n => n === "approvals") &&
                                <li><Link to='/approvals'>Our Approvals</Link></li>
                            }
                            {
                                navs.find(n => n === "courses") &&
                                <li>
                                    {
                                        bootstrapService.singleCourseId &&
                                        <Link to={`/course/${bootstrapService.singleCourseId}`}>The Course</Link>
                                    }
                                    {
                                        !bootstrapService.singleCourseId &&
                                        <Link to="/courses">Our Courses</Link>
                                    }
                                </li>
                            }
                            {
                                navs.find(n => n === "pricing") &&
                                <li><Link to={`/pricing/${defaultPassType}`}>Pricing</Link></li>
                            }
                            {
                                navs.find(n => n === "blog") &&
                                <li><Link to="/blog">Blog</Link></li>
                            }
                            {
                                navs.find(n => n === "faq") &&
                                <li><Link to='/faq'>FAQ</Link></li>
                            }
                        </ul>
                    </div>
                    <div className={styles['list']}>
                        <h4 className={styles['title']}>Useful Links</h4>
                        <ul>
                            {
                                navs.find(n => n === "help-support") &&
                                <li><Link to='/help-and-support'>Help & Support</Link></li>
                            }
                            {
                                navs.find(n => n === "requirements") &&
                                <li><Link to={`/requirements/${defaultStateName}`}>{ceTerm} Requirements</Link></li>
                            }
                            {
                                navs.find(n => n === "our-sites") &&
                                <li><Link to="/affiliated-sites">Affiliated Sites</Link></li>
                            }
                            {
                                navs.find(n => n === "terms") &&
                                <li><Link to="/terms-and-conditions">Terms & Conditions</Link></li>
                            }
                            {
                                navs.find(n => n === "privacy") &&
                                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                            }
                            {
                                navs.find(n => n === "refund-policy") &&
                                <li><Link to="/refund-policy">Refund Policy</Link></li>
                            }
                            {
                                navs.find(n => n === "complaint-resolution-policy") &&
                                <li><Link to="/complaint-resolution-policy">Complaint Resolution Policy</Link></li>
                            }
                        </ul>
                    </div>
                    <div className={styles['list']}>
                        <h4 className={styles['title']}>Contacts</h4>
                        <ul>
                            <li>Phone: <a href={`tel:${phone}`}> {phone}</a></li>
                            <li>Fax: <a href={`fax:${fax}`}> {fax}</a></li>
                            <li><a href={`mailto:${email}`} target="_blank" rel="noreferrer">{email}</a></li>
                            {
                                showAddress &&
                                <li><a href={`http://maps.google.com/?q=${address}`} target="_blank" rel="noreferrer">{address}</a></li>
                            }
                        </ul>
                    </div>
                </div>
                <div className={styles['info']}>
                    <div className={styles['brand']}>
                        <img src={logo} alt='' className={styles['logo']} onClick={this.clickLogo} />
                        <span className={styles['copyright']}>© Copyright {year} {siteName} v2.0.8</span>
                    </div>
                    {
                        appleUrl &&
                        <a href={appleUrl} target="_blank" rel="noreferrer">
                            <AppStoreButton className={styles['button']} noAnimate={true} />
                        </a>
                    }
                </div>
            </section>
        )
    }
}

export default withRouter(Footer);