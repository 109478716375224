import React from 'react'

import image from './images/individual-course.svg'
import PrimaryButton from '../../../shared/Buttons/PrimaryButton/PrimaryButton'

import styles from './BuyCourseCard.module.css'

class BuyCourseCard extends React.Component {
    render() {
        const title = this.props.data.title; 
        const price = this.props.data.item.price;

        return (
            <div className={styles['content']} ref={this.props.innerRef}>
                <div className={styles['circle']}>
                    <img src={image} alt='' className={styles['image']} />
                </div>
                <div className={styles['info']}>
                    <div className={styles['info-container']}>
                        <h3 className={styles['title']}>Buy the individual course</h3>
                        <p className={styles['description']}>{title}</p>
                        {
                            this.props.schedule &&
                            <p className={styles['schedule']}><span className={styles['date']}>Date: </span>{this.props.schedule}</p>
                        }
                    </div>
                    <div className={styles['buy-container']}>
                        <div className={styles['price']}>
                            <span className={styles['dollars']}>${String(price).split('.')[0]}</span>
                            <span className={styles['cents']}>.{String(price).split('.')[1] ? String(price).split('.')[1] : '00'}</span>
                        </div>
                        <PrimaryButton className={styles['button']} noAnimate={true} onClick={this.props.onClick}>Buy Now</PrimaryButton>
                    </div>
                </div>
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <BuyCourseCard 
  innerRef={ref} {...props}
/>);