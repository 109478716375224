import React from 'react'

import ArrowButton from '../../../shared/Buttons/ArrowButton/ArrowButton';

import styles from './AllBuyOptionsButton.module.css'


class AllBuyOptionsButton extends React.Component {
    render() {
        return (
            <div className={`${styles['content']} ${this.props.className}`} ref={this.props.innerRef} onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave}>
                <span className={styles['text']}>View all purchase options</span>
                <ArrowButton className={styles['button']} />
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <AllBuyOptionsButton 
  innerRef={ref} {...props}
/>);