import React from 'react'
import gsap from 'gsap'

import plusImage from './images/plus.svg'

import styles from './Question.module.css'

class Question extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.circle = null;
        this.plus = null;
        this.answer = null;

        // Vars
        this.answerHeight = null;

        // This Binding
        this.clickFAQ = this.clickFAQ.bind(this);
        this.animateCircleEnter = this.animateCircleEnter.bind(this);
        this.animateCircleLeave = this.animateCircleLeave.bind(this);
    }

    // Lifecycle
    componentDidMount() {
        this.setupAnswer();
    }

    componentDidUpdate() {
        if (!this.props.isOpen) {
            gsap.to(this.answer, {
                duration: 0.5,
                marginTop: this.answerHeight,
                onComplete: this.props.refreshScrollTrigger,
            })

            this.plus.classList.remove(styles['plus-open']);
        }
    }

    // Helpers
    setupAnswer() {
        // Timeout hack ensures function runs after Render (so that REFs are set)
        setTimeout(() => {
            if (this.answer) {
                const style = getComputedStyle(this.answer);
                this.answerHeight = -(parseInt(style.height) + 20);
                this.answer.style.marginTop = `${this.answerHeight}px`;
            }
        }, 0);
    }

    // Event Handling
    clickFAQ(e) {
        if (!this.props.isOpen) {
            gsap.to(this.answer, {
                duration: 0.5,
                marginTop: 0,
                onComplete: this.props.refreshScrollTrigger,
            })
            this.plus.classList.add(styles['plus-open']);
            this.props.didOpen(this.props.index);
        }
        else {
            gsap.to(this.answer, {
                duration: 0.5,
                marginTop: this.answerHeight,
                onComplete: this.props.refreshScrollTrigger,
            })
            this.plus.classList.remove(styles['plus-open']);
            this.props.didClose(this.props.index);
        }
    }

    animateCircleEnter() {
        gsap.to(this.circle, {
            duration: 0.3,
            scale: 1.05,
        })
    }

    animateCircleLeave() {
        gsap.to(this.circle, {
            duration: 0.3,
            scale: 1,
        })
    }

    // Component
    render() {
        const { question, answer } = this.props.faq;

        return (
            <div className={styles['content']} ref={this.props.innerRef} onClick={this.clickFAQ}>
                <div className={styles['top']}>
                    <h3 className={styles['question']} dangerouslySetInnerHTML={{ __html: question}}></h3>
                    <div className={styles['circle']} ref={ref => this.circle = ref} onMouseEnter={this.animateCircleEnter} onMouseLeave={this.animateCircleLeave}>
                        <div className={styles['plus']} ref={ref => this.plus = ref}></div>
                        {/* <img src={plusImage} alt='' className={styles['image']} /> */}
                    </div>
                </div>
                <div className={styles['answer-container']}>
                    <p className={styles['answer']} ref={ref => this.answer = ref} dangerouslySetInnerHTML={{ __html: answer}}></p>
                </div>
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <Question 
  innerRef={ref} {...props}
/>);