import React from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import image1 from './images/1.png'
import image2 from './images/2.png'
import image3 from './images/3.png'
import image4 from './images/4.png'
import image5 from './images/5.png'
import image6 from './images/6.png'
import WhyUsCard from './components/WhyUsCard/WhyUsCard'

import styles from './WhyUs.module.css'

class WhyUs extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.title = null;
        this.cards = [];

        // GSAP
        gsap.registerPlugin(ScrollTrigger);
    }

    // Lifecycle 
    componentDidMount() {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 0);

        this.setupAnimations();
    }

    setupAnimations() {
        gsap.from(this.title, {
            scrollTrigger: {
                trigger: this.title,
                start: 'top 90%',
            },
            duration: 0.5,
            y: 25,
            opacity: 0,
        });

        for (const [index, card] of this.cards.entries()) {
            gsap.from(card, {
                scrollTrigger: {
                    trigger: card,
                    start: 'top 90%',
                },
                duration: 0.5,
                delay: window.innerWidth >= 768 && index % 2 !== 0 ? 0.25 : 0,
                y: 25,
                opacity: 0,
                scale: window.innerWidth >= 768 ? 0.95 : 1,
            });
        }
    }

    // Component
    render() {
        const { header, features } = this.props.data;
        const images = [image1, image2, image3, image4, image5, image6];

        return (
            <section className={styles['content']}>
                <h1 className={styles['title']} ref={ref => this.title = ref}>{header}</h1>
                <div className={styles['cards']}>
                    <div className={styles['row']}>
                        <WhyUsCard data={features[0]} image={images[0]} className={styles['card-1']} ref={ref => this.cards[0] = ref} />
                        <WhyUsCard data={features[1]} image={images[1]} className={styles['card-2']} ref={ref => this.cards[1] = ref} inverted={true}  />
                    </div>
                    <div className={styles['row']}>
                        <WhyUsCard data={features[2]} image={images[2]} className={styles['card-3']} ref={ref => this.cards[2] = ref} />
                        <WhyUsCard data={features[3]} image={images[3]} className={styles['card-4']} ref={ref => this.cards[3] = ref} />
                    </div>
                    <div className={styles['row']}>
                        <WhyUsCard data={features[4]} image={images[4]} className={styles['card-5']} ref={ref => this.cards[4] = ref} />
                        <WhyUsCard data={features[5]} image={images[5]} className={styles['card-6']} ref={ref => this.cards[5] = ref}inverted={true} />
                    </div>
                </div>
            </section>
        )
    }
}

export default WhyUs;