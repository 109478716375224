import React from 'react'
import gsap from 'gsap'

import ArrowButton from '../../../shared/Buttons/ArrowButton/ArrowButton'

import styles from './SiteCard.module.css'

class SiteCard extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.arrow = null;

        // This Binding
        this.animateArrowEnter = this.animateArrowEnter.bind(this);
        this.animateArrowLeave = this.animateArrowLeave.bind(this);
    }

    // Event Handlers
    animateArrowEnter() {
        gsap.to(this.arrow, {
            duration: 0.3,
            scale: 1.05,
        })
    }

    animateArrowLeave() {
        gsap.to(this.arrow, {
            duration: 0.3,
            scale: 1,
        })
    }

    // Component
    render() {
        const { url, imageUrl, body } = this.props.data;

        return (
            <a href={url} target="_blank" rel="noopener noreferrer" className={styles['link']} onMouseEnter={this.animateArrowEnter} onMouseLeave={this.animateArrowLeave}>
                <div className={styles['content']} ref={this.props.innerRef}>
                    <div className={styles['logo']} >
                        <img src={imageUrl} alt='' />
                    </div>
                    <p className={styles['description']}>{body}</p>
                    <ArrowButton className={styles['button']} ref={ref => this.arrow = ref} />
                </div>
            </a>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <SiteCard 
  innerRef={ref} {...props}
/>);